import { ReactComponent as RateIcon } from '@/assets/svg/research/rating.svg';

import EmptyState from '../EmptyState';

const Ratings = () => {
  return (
    <div className="flex bg-white flex-col gap-4 p-4 rounded-lg">
      <span className="text-sm font-semibold text-darkest">Ratings</span>
      <EmptyState
        icon={<RateIcon />}
        onClick={() => {}}
        title="You haven’t rated this fund"
        buttonLabel="Add rating"
      />
    </div>
  );
};

export default Ratings;
