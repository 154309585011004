import React from 'react';

import { useSignificantChangesContext } from './Context';
import {
  SIGNIFICANT_CHANGES_ACTIONS,
  SignificantChangesActionTypes,
  SignificantChangesParameters,
  SignificantChangesState,
} from './types';

export const useSignificantChanges = ({
  state,
  dispatch: significantChangesDispatch,
}: {
  state: SignificantChangesState;
  dispatch: React.Dispatch<SignificantChangesActionTypes>;
}) => {
  const setSignificantChangeParameters = (
    params: Partial<SignificantChangesParameters>,
  ) => {
    significantChangesDispatch({
      type: SIGNIFICANT_CHANGES_ACTIONS.SET_SIGNIFICANT_CHANGES_PARAMETERS,
      payload: {
        ...params,
      },
    });
  };

  const clearFilter = () => {
    significantChangesDispatch({
      type: SIGNIFICANT_CHANGES_ACTIONS.CLEAR_FILTER,
    });
  };

  const anyFiltersSelected = () => {
    const filterParams = {
      changeType: state.significantChangesParameters.changeTypes,
      period: state.significantChangesParameters.period,
    };
    return !Object.values(filterParams).every(
      o => o === undefined || o === '' || o.length === 0,
    );
  };

  return {
    state,
    setSignificantChangeParameters,
    clearFilter,
    anyFiltersSelected,
  };
};

const useProvideSignificantChanges = () => {
  const { state, dispatch } = useSignificantChangesContext();

  return useSignificantChanges({ state, dispatch });
};

export default useProvideSignificantChanges;
