const SearchSpinner = () => {
  return (
    <div className="flex items-center gap-2">
      <span className="text-xs text-neutral">Searching</span>
      <div className="flex gap-1">
        <span className="w-1 h-1 bg-yellow-500 rounded-full animate-bounce [animation-delay:-0.3s]"></span>
        <span className="w-1 h-1 bg-red-500 rounded-full animate-bounce [animation-delay:-0.15s]"></span>
        <span className="w-1 h-1 bg-blue-500 rounded-full animate-bounce"></span>
      </div>
    </div>
  );
};

export default SearchSpinner;
