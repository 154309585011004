import React, { FC } from 'react';

import { ReactComponent as EmptyNoteIcon } from '@/assets/svg/research/empty-note.svg';

interface EmptyStateProps {
  onClick: () => void;
  title: string;
  subTitle?: string;
  buttonLabel: string;
  icon?: React.ReactNode;
}

const MainEmptyState: FC<EmptyStateProps> = ({
  title,
  subTitle,
  icon = <EmptyNoteIcon className="h-16 w-16" />,
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full text-center">
      {icon}
      <div className="flex flex-col gap-y-2 mb-6">
        <h3 className="text-base not-italic font-semibold mt-2">{title}</h3>
        {subTitle && <p className="leading-5 text-neutral-200">{subTitle}</p>}
      </div>
    </div>
  );
};

export default MainEmptyState;
