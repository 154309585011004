import { Button } from 'antd';
import cx from 'classnames';
import React, { ComponentProps } from 'react';

import style from './style.module.less';

interface ButtonProps extends ComponentProps<typeof Button> {
  size?: 'large' | 'middle' | 'small';
  block?: boolean;
  danger?: boolean;
  disabled?: boolean;
  // ghost?: boolean;
  loading?: boolean;
  htmlType?: 'button' | 'submit';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.ReactNode;
  // type?: 'ghost' | 'link' | 'text' | 'primary' | 'default' | 'dashed';
  type?: 'link' | 'text' | 'primary' | 'default' | 'dashed';
  className?: string;
}

const CustomButton: React.FCWithChild<ButtonProps> = ({
  children,
  size,
  block,
  danger,
  disabled,
  ghost,
  loading,
  type,
  onClick,
  icon,
  htmlType,
  className,
  ...props
}) => {
  return (
    <Button
      className={cx(
        style['custom-btn'],
        `text-sm background-primary rounded border-0
        py-2.5 px-4 -outline-offset-1 h-auto
        w-full md:w-auto landscape:w-auto`,
        className,
      )}
      type={type}
      block={block}
      danger={danger}
      disabled={disabled}
      ghost={ghost}
      loading={loading}
      htmlType={htmlType}
      onClick={onClick}
      size={size}
      icon={icon}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
