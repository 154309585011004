import { Slider } from 'antd';

import Accordion, { Panel } from '@/components/Accordion';
import { getSliderValueOrDefault, SLIDER_MAX } from '@/constants';
import RenderStyleBox from '@/partials/RenderStyleBox';

import {
  equitiesFilterMarks,
  equitiesInitialState,
  SLIDER_STEPS,
} from '../hooks/equitiesSubState';
import useExploreFilters from '../hooks/useExploreFilters';
import { adjustMarksToAvoidDuplicates } from '../utils/adjustMarksToAvoidDuplicates';
import { getChangedMarkValues } from '../utils/getChangedMarkValues';
import { getRangeSliderClassName } from '../utils/getRangeSliderClassName';
import lengthOrEmpty from '../utils/lengthOrEmpty';
import style from './style.module.less';

const EquitiesFilterPanels = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();

  const equitiesFilters =
    tentativeFilters.equitiesFilters ?? equitiesInitialState;

  const [roicRangeMin, roicRangeMax] = getSliderValueOrDefault(
    equitiesFilters.roicRange,
  );
  const [netMarginRangeMin, netMarginRangeMax] = getSliderValueOrDefault(
    equitiesFilters.netMarginRange,
  );

  return (
    <div>
      <Accordion defaultActiveKey="1">
        <Panel
          header="Style"
          dataTestId={'equitiesLabelStyle'}
          key="1"
          extra={lengthOrEmpty(tentativeFilters.equitiesFilters?.equityStyle)}
        >
          <div className="relative">
            <div className="flex">
              <RenderStyleBox
                isBoxClickable
                onSelectedBox={(selected: number[]) => {
                  const filters = getMutableFilters();
                  filters.equitiesFilters = {
                    ...filters.equitiesFilters,
                    equityStyle: selected.length > 0 ? selected : undefined,
                  };
                  updateTentativeFilters(filters);
                }}
                isClear={
                  !tentativeFilters.equitiesFilters?.equityStyle ||
                  tentativeFilters.equitiesFilters?.equityStyle?.length === 0
                }
                selectedBox={tentativeFilters.equitiesFilters?.equityStyle}
              />
            </div>
            <p className="font-normal text-xs absolute top-[40%] transform rotate-90 sm:left-[17.2rem] left-[15.2rem] text-neutral">
              Size
            </p>
            <p className="text-xs font-normal pl-24 sm:pl-[7.1rem] text-neutral">
              Style
            </p>
          </div>
        </Panel>
        <Panel
          header="ROIC"
          dataTestId={'equitiesLabelROIC'}
          key="2"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.equitiesFilters?.roicRange,
                equitiesFilterMarks,
              )}
            </span>
          }
        >
          <div
            className={style['range-slider']}
            data-test-id={'equitiesSliderROIC'}
          >
            <Slider
              className={
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.equitiesFilters?.roicRange,
                  )
                ]
              }
              tooltip={{ open: false }}
              range
              step={SLIDER_STEPS}
              max={SLIDER_MAX}
              marks={equitiesFilterMarks}
              value={[roicRangeMin, roicRangeMax]}
              onChange={value => {
                const filters = getMutableFilters();
                filters.equitiesFilters = {
                  ...filters.equitiesFilters,
                  roicRange: adjustMarksToAvoidDuplicates(
                    value,
                    equitiesFilterMarks,
                  ),
                };
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </Panel>
        <Panel
          header="Net Margin"
          dataTestId={'equitiesLabelNetMargin'}
          key="3"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.equitiesFilters?.netMarginRange,
                equitiesFilterMarks,
              )}
            </span>
          }
        >
          <div
            className={style['range-slider']}
            data-test-id={'equitiesSliderNetMargin'}
          >
            <Slider
              className={
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.equitiesFilters?.netMarginRange,
                  )
                ]
              }
              tooltip={{ open: false }}
              range
              step={SLIDER_STEPS}
              max={SLIDER_MAX}
              marks={equitiesFilterMarks}
              value={[netMarginRangeMin, netMarginRangeMax]}
              onChange={value => {
                const filters = getMutableFilters();
                filters.equitiesFilters = {
                  ...filters.equitiesFilters,
                  netMarginRange: adjustMarksToAvoidDuplicates(
                    value,
                    equitiesFilterMarks,
                  ),
                };
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </Panel>
      </Accordion>
    </div>
  );
};

export default EquitiesFilterPanels;
