import { NlpApi } from '@aminsights/contract';
import { useMutation } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

const nlpApi = new NlpApi(openApiConfig());

export const useTranslateSearch = () => {
  let abortController: AbortController | null = null;

  const mutation = useMutation(async (input: { term: string }) => {
    if (abortController) abortController.abort();
    abortController = new AbortController();
    const signal = abortController.signal;

    try {
      const response = await nlpApi.getTranslateSearch(input.term, {
        signal,
      });
      abortController = null;
      return response;
    } catch (error: any) {
      abortController = null;
      if (error.code !== 'ERR_CANCELED') throw error;
    }
  });

  const cancel = () => {
    if (abortController) {
      abortController.abort();
    }
  };

  return { ...mutation, cancel };
};
