import cx from 'classnames';

import { ReactComponent as CheckActive } from '@/assets/svg/icons/icon-check-active.svg';

interface OptionProps {
  isActive: boolean;
  dataTestId: string;
  label: string;
}

const Option = ({ isActive, dataTestId, label }: OptionProps) => {
  return (
    <div
      className={cx(
        'flex items-center justify-between w-full p-2',
        isActive && 'text-[#0072e6]',
      )}
      data-test-id={dataTestId}
    >
      {label} {isActive && <CheckActive className="active-icon" />}
    </div>
  );
};

export default Option;
