import { Modal } from 'antd';
import { useState } from 'react';

import useSearchWithPortfolios from '@/hooks/query-hooks/search-hooks/useSearchWithPortfolio';
import useAutoFocusRef from '@/hooks/useAutoFocusRef';
import useChartTrustFundsFromSearch from '@/pages/app/Charts/utils/useChartFundsFromSearch';
import ExploreSearchV2, {
  renderOptions,
} from '@/partials/ExploreSearchBox/ExploreSearchBoxV2';
import { useSearchBox } from '@/partials/ExploreSearchBox/useExploreSearchbox';

import { useChartingContext } from './ChartingContext';

interface Props {
  activeTab: string;
}

const ChartSearchBox = ({ activeTab }: Props) => {
  const searchConfig = useSearchWithPortfolios();
  const chartingCtx = useChartingContext();
  const [maxPortfoliosModalVisible, setMaxPortfoliosModalVisible] =
    useState(false);
  const { setIsins, isins, setPortfolios, portfolios } =
    useChartTrustFundsFromSearch();
  const searchBoxOpts = useSearchBox({
    searchConfig,
    onClose: () => {},
    defaultValue: '',
  });
  const resultOpts = searchBoxOpts.allSuggestions.map(opt =>
    renderOptions({
      value: opt.id,
      title: opt?.shareClassDetails?.code || opt.label,
      highlightTerm: searchConfig.fullTerm,
      isPortfolio: opt.isPortfolio,
      primaryShareClass: opt.primaryShareClass,
      disabled: isins.includes(opt.id) || portfolios.includes(opt.id),
    }),
  );
  const totalPortfolios = portfolios?.length || 0;
  const totalItems = (isins?.length || 0) + totalPortfolios;
  const maxPortfoliosReached = totalPortfolios >= 5;

  const handleShowMaxPortfoliosModal = () => {
    setMaxPortfoliosModalVisible(true);
    searchConfig.setFullTerm('');
  };

  const searchRef = useAutoFocusRef<HTMLDivElement>([activeTab]);

  return (
    <>
      <ExploreSearchV2
        dataTestId="chartingToolSearch"
        ref={searchRef}
        disabled={totalItems >= 20}
        value={searchConfig.fullTerm}
        onChange={searchBoxOpts.onChange}
        loading={searchBoxOpts.isLoading}
        onSelect={id => {
          const optSelected = searchBoxOpts.allSuggestions.find(
            s => s.id === id,
          );
          if (optSelected) {
            if (optSelected.isPortfolio) {
              if (maxPortfoliosReached) {
                handleShowMaxPortfoliosModal();
                return;
              }
              setPortfolios([id]);
            } else {
              setIsins([id]);
            }
            chartingCtx.setRecentId(id);
            searchConfig.setFullTerm('');
          }
        }}
        onKeyEnter={async id => {
          const optSelected = searchBoxOpts.allSuggestions.find(
            s => s.id === id,
          );
          if (!optSelected) {
            const firstItem = searchBoxOpts.allSuggestions[0];
            if (firstItem) {
              if (firstItem.isPortfolio) {
                if (maxPortfoliosReached) {
                  handleShowMaxPortfoliosModal();
                  return;
                }
                setPortfolios([firstItem.id]);
              } else {
                setIsins([firstItem.id]);
              }
              chartingCtx.setRecentId(firstItem.id);
              searchConfig.setFullTerm('');
            }
            return;
          }
          if (optSelected.isPortfolio) {
            if (maxPortfoliosReached) {
              handleShowMaxPortfoliosModal();
              return;
            }
            setPortfolios([optSelected.id]);
          } else {
            setIsins([optSelected.id]);
          }
          chartingCtx.setRecentId(optSelected.id);
          searchConfig.setFullTerm('');
        }}
        onSubmit={searchBoxOpts.onSearchResults}
        options={resultOpts}
      />
      <Modal
        open={maxPortfoliosModalVisible}
        closable={true}
        footer={[
          <p
            className="text-sm text-center cursor-pointer font-medium text-[#3B3C53] hover:text-red-500 w-full"
            onClick={() => setMaxPortfoliosModalVisible(false)}
          >
            Close
          </p>,
        ]}
        onCancel={() => setMaxPortfoliosModalVisible(false)}
        className="max-sm:full-page-modal action-modal [&_.ant-modal-footer]:md:!justify-end"
        title="Portfolio limit reached"
        centered={true}
      >
        <div className="flex flex-col gap-y-4">
          <p className="text-sm font-normal text-[#545576] mb-4">
            You can only add up to 5 portfolios here.
            <br />
            <br />
            If you would like to add more, let us know by contacting our support
            team so we can add it to the roadmap.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ChartSearchBox;
