import { Button, Tooltip } from 'antd';
import clsx from 'classnames';
import { useState } from 'react';

import { ReactComponent as SparklesIcon } from '@/assets/svg/icons/icon-sparkles.svg';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import AISearchModal from './AISearchModal';

export const AISearchWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [firstTap, setFirsTap] = useState(true);
  const screenWidthMode = getScreenWidthMode();
  const isTablet = screenWidthMode[ScreenWidthEnum.MaxLg];

  const { state } = useFeatureSwitchContext();

  const handleTooltipVisibleChange = (
    visible: boolean | ((prevState: boolean) => boolean),
  ) => {
    setTooltipVisible(visible);
    if (!visible) {
      setFirsTap(true);
    }
    if (firstTap) {
      setTooltipVisible(true);
      setFirsTap(false);
    }
  };

  return state.isAiSearchEnabled ? (
    <>
      <AISearchModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Tooltip
        placement="topLeft"
        trigger={isTablet ? 'click' : 'hover'}
        open={tooltipVisible}
        onOpenChange={handleTooltipVisibleChange}
        title={
          <div className="flex flex-col gap-0.5">
            <p className="text-xs font-semibold leading-5">Ask AI</p>
            <p>Looking for funds? Try asking for help using AI chat.</p>
          </div>
        }
        color="#313341"
        overlayClassName="w-60"
        overlayInnerStyle={{ borderRadius: 4, padding: 12 }}
        arrow={false}
      >
        <Button
          className={clsx('w-12 h-12 fixed bottom-4 right-4 z-30 shadow-lg', {
            hidden: isOpen,
          })}
          onClick={() => (!isTablet || !firstTap) && setIsOpen(true)}
          type="primary"
          shape="circle"
          size="large"
          icon={<SparklesIcon />}
        />
      </Tooltip>
    </>
  ) : null;
};
