import { Performance } from '@aminsights/contract';

export const isPerformance = (item: unknown): item is Performance => {
  return (
    item !== null &&
    typeof item === 'object' &&
    'isPerformance' in item &&
    (item as { isPerformance: unknown }).isPerformance === true
  );
};
