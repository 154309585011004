import {
  DATE_PERIOD_FILTER,
  EDateFilterValues,
  MS_DATE_FORMAT,
} from '@aminsights/shared';
import { ArrowRightOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { DatePicker, Drawer, Dropdown, Menu } from 'antd';
import SkeletonButton from 'antd/lib/skeleton/Button';
import cx from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as Arrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import Button from '@/components/Button';
import useScreenWidth, { EScreenSize } from '@/hooks/screenWidth';
import { rollbackDayIfWeekend } from '@/partials/Charts/utils/chart-data';

import style from '../style.module.less';

const { RangePicker } = DatePicker;
export type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof RangePicker>['onChange']>
>[0];
export interface DatePickerValue {
  range?: RangeValue;
  mode: EDateFilterValues;
}

export const parseDateValue = (opt: EDateFilterValues): RangeValue => {
  const endDate = dayjs().subtract(1, 'days');
  let startDate = endDate;
  switch (opt) {
    case EDateFilterValues['7D']:
      startDate = dayjs().subtract(7, 'day');
      break;
    case EDateFilterValues['30D']:
      startDate = dayjs().subtract(30, 'day');
      break;
    case EDateFilterValues.YTD:
      startDate = dayjs(`12-31-${dayjs().get('year') - 1}`, 'MM-DD-YYYY');
      break;
    case EDateFilterValues['1YR']:
      startDate = dayjs().subtract(1, 'year');
      break;
    case EDateFilterValues['3YR']:
      startDate = dayjs().subtract(3, 'year');
      break;
    case EDateFilterValues['5YR']:
      startDate = dayjs().subtract(5, 'year');
      break;
  }

  const result: RangeValue = [startDate, endDate];
  return result;
};

export const parseRangeValues = (
  rangeValue: RangeValue,
  mode?: EDateFilterValues,
  format = MS_DATE_FORMAT,
) => {
  let range = rangeValue;
  if (!!mode && mode !== EDateFilterValues.YTD) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    range = [rangeValue![0]!.subtract(1, 'days'), rangeValue![1]!];
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  range = [rollbackDayIfWeekend(range![0]!), range![1]!];
  return (range || [dayjs(), dayjs()]).map(d => {
    if (d) {
      return d.format(format);
    }
    return dayjs().format(format);
  });
};

interface Props {
  value?: DatePickerValue;
  onChange?: (value: RangeValue, mode: EDateFilterValues) => void;
  defaultValue?: DatePickerValue;
  disabled?: boolean;
  btnClassName?: string;
  loading?: boolean;
  dataTestId?: string;
}

const RangeDatePicker: React.FCWithChild<Props> = ({
  value,
  onChange,
  defaultValue = { mode: EDateFilterValues['30D'] },
  disabled,
  btnClassName,
  loading,
  dataTestId,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [dateVisible, setDateVisible] = useState(false);
  const [mobileDateVisible, setMobileDateVisible] = useState(false);
  const [rangeValues, setRangeValues] = useState<RangeValue>(
    defaultValue?.range || [dayjs(), dayjs()],
  );

  const [selectedMenu, setSelectedMenu] = useState<EDateFilterValues[]>([]);
  const containerRef = useRef(null);
  const { isCurrentWidth } = useScreenWidth();
  const isMobile =
    isCurrentWidth(EScreenSize.xs) || isCurrentWidth(EScreenSize.sm);

  const handleClosePicker = () => {
    setIsVisible(false);
  };
  const handleCloseMobilePicker = () => {
    setMobileDateVisible(false);
  };
  const handleApply = () => {
    if (rangeValues?.every(Boolean)) {
      onChange?.(rangeValues, selectedMenu[0]);
      handleClosePicker();
      if (isMobile) {
        handleCloseMobilePicker();
      }
    }
  };
  const handleMenuChange = (val: EDateFilterValues) => {
    setSelectedMenu([val]);
    if (val !== EDateFilterValues.CUSTOM) {
      onChange?.(parseDateValue(val), val);
      handleClosePicker();
    }
  };
  const handleMenuClick = ({ key }: { key: string }) => {
    handleMenuChange(key as EDateFilterValues);
    if (key === EDateFilterValues.CUSTOM && isMobile) {
      setMobileDateVisible(true);
      handleClosePicker();
    }
  };
  const handleDatePickerChange = (value: RangeValue) => {
    setSelectedMenu([EDateFilterValues.CUSTOM]);
    setRangeValues(value);
  };
  const resetDefault = (): RangeValue => {
    if (
      defaultValue?.mode !== EDateFilterValues.CUSTOM &&
      !defaultValue?.range
    ) {
      const rangeValue = DATE_PERIOD_FILTER.find(
        opt => opt.value === defaultValue?.mode,
      )?.value;
      if (rangeValue) {
        return parseDateValue(rangeValue);
      }
    } else if (defaultValue?.range) {
      return defaultValue.range;
    }
    return null;
  };
  const handleClear = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    onChange?.(resetDefault(), defaultValue.mode);
  };

  useEffect(() => {
    setDateVisible(isMobile ? mobileDateVisible : isVisible);
  }, [isVisible, mobileDateVisible, isMobile]);

  useEffect(() => {
    setRangeValues(resetDefault());
  }, []);

  useEffect(() => {
    if ((isVisible || mobileDateVisible) && value) {
      if (value.range) {
        setRangeValues(value.range);
      }
      setSelectedMenu([value.mode]);
    }
  }, [isVisible, value, mobileDateVisible]);

  useEffect(() => {
    handleCloseMobilePicker();
    handleClosePicker();
  }, [isMobile]);

  const buttonText = () => {
    const currOpt = DATE_PERIOD_FILTER.find(opt => opt.value === value?.mode);
    if (currOpt?.value !== 'CUSTOM') {
      return (
        <>
          {currOpt?.label}{' '}
          <Arrow
            className={cx(style['dropdown-button-caret'], 'icon', {
              [style['dropdown-button-caret-up']]: isVisible,
            })}
          />
        </>
      );
    }
    if (value?.range?.[0] && value?.range?.[1]) {
      return (
        <>
          {value.range[0].format(MS_DATE_FORMAT)}{' '}
          <ArrowRightOutlined className="flex-1" />
          {` ${value.range[1].format(MS_DATE_FORMAT)}`}
          {!isVisible && (
            <CloseCircleOutlined
              className={style['dropdown-button-clear']}
              onClick={handleClear}
            />
          )}
        </>
      );
    }
    return '';
  };

  const DateRangePicker = (
    <RangePicker
      open={dateVisible}
      getPopupContainer={() => {
        if (containerRef.current) {
          return containerRef.current;
        }
        return document.body;
      }}
      onCalendarChange={handleDatePickerChange}
      value={rangeValues}
      disabledDate={d => dayjs().diff(d, 'days') < 1}
    />
  );
  const ApplyButton = (
    <Button
      type="primary"
      onClick={handleApply}
      disabled={!rangeValues?.every(Boolean)}
    >
      Apply
    </Button>
  );

  return (
    <>
      <Dropdown
        disabled={disabled || loading}
        open={isVisible}
        trigger={['click']}
        overlay={() => (
          <div className={cx(style['dropdown-container'])}>
            <Menu
              className={cx(style['dropdown-menu'])}
              selectedKeys={selectedMenu}
              onClick={handleMenuClick}
              items={DATE_PERIOD_FILTER.map(opt => ({
                label: opt.label,
                key: opt.value,
              }))}
            />
            {!isMobile && (
              <div className={cx(style['dropdown-inner-container'])}>
                <div
                  ref={containerRef}
                  className={cx(style['dropdown-date-container'])}
                />
                <div className={cx(style['dropdown-footer'])}>
                  {DateRangePicker}
                  <div className={cx(style['dropdown-footer-buttons'])}>
                    <Button
                      type="default"
                      onClick={handleClosePicker}
                      className="border-none"
                    >
                      Cancel
                    </Button>
                    {ApplyButton}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      >
        {loading ? (
          <SkeletonButton active className={cx(btnClassName, 'h-10')} />
        ) : (
          <Button
            onClick={() => setIsVisible(true)}
            className={cx(
              style['dropdown-button'],
              isVisible && style['active'],
              btnClassName,
            )}
            data-test-id={dataTestId}
          >
            {buttonText()}
          </Button>
        )}
      </Dropdown>
      {isMobile && (
        <Drawer
          open={mobileDateVisible}
          placement="bottom"
          closable
          onClose={handleCloseMobilePicker}
          height="95%"
          footer={
            <div className={style['dropdown-mobile-date-footer']}>
              {ApplyButton}
            </div>
          }
        >
          <div
            className={style['dropdown-mobile-date-container']}
            ref={containerRef}
          >
            {DateRangePicker}
          </div>
        </Drawer>
      )}
      {isVisible && (
        <div
          aria-label="Close dropdown"
          className={cx(style['dropdown-overlay'])}
          onClick={handleClosePicker}
          onKeyUp={handleClosePicker}
          tabIndex={0}
          role="button"
        />
      )}
    </>
  );
};

export default RangeDatePicker;
