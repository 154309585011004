import { Fund } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  isInvestmentTrust,
  MsTimePeriod,
  RipsReturnType,
} from '@aminsights/shared';
import { FC } from 'react';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { roundOrEmpty } from '@/utils/roundOrEmpty';

interface Props {
  fund?: Fund;
  timePeriod?: MsTimePeriod;
  format?: (value: number | undefined) => string;
  /**
   * If you explicitly want to show a specific return type, otherwise it will default
   * to Nav for Fund and Share Price for Trusts.
   */
  returnTypeOverride?: RipsReturnType.TotalReturn | RipsReturnType.SharePrice;
}

export const RenderStandardDeviation: FC<Props> = ({
  fund,
  timePeriod = MsTimePeriod.ONE_YR,
  format,
  returnTypeOverride,
}) => {
  const { state } = useFeatureSwitchContext();
  const { isComputedRiskDetailsTreatmentEnabled } = state;

  if (!fund) return EMPTY_DATA_POINT;

  const isTrust = isInvestmentTrust(fund.legalStructure);

  const standardReturnType = isTrust
    ? RipsReturnType.SharePrice
    : RipsReturnType.TotalReturn;

  const returnType = returnTypeOverride ?? standardReturnType;

  const computedRiskDetails =
    returnType === RipsReturnType.SharePrice
      ? fund?.computedSharePriceRiskDetails
      : fund?.computedNavRiskDetails;

  const targetRiskDetails = isComputedRiskDetailsTreatmentEnabled
    ? computedRiskDetails
    : /**
       * This value is Share Price for Trusts but Nav for Funds. So from a charting perspective
       * it is impossible to toggle between the two. But after FS is killed we can thanks to the
       * Share Price Risks being stored as a separate field.
       */
      fund?.riskMeasuresDetail;

  const matchingTimePeriod = targetRiskDetails?.find(
    c => c.timePeriod === timePeriod,
  );

  const standardDeviation = matchingTimePeriod?.standardDeviation;

  return format
    ? format(standardDeviation)
    : roundOrEmpty(standardDeviation, 2, '%');
};
