import { ReactComponent as CalendarIcon } from '@/assets/svg/research/calendar.svg';

import EmptyState from '../EmptyState';

const Meetings = () => {
  return (
    <div className="flex bg-white flex-col gap-4 p-4 rounded-lg">
      <span className="text-sm font-semibold text-darkest">Meetings</span>
      <EmptyState
        icon={<CalendarIcon />}
        onClick={() => {}}
        title="You haven’t logged any meetings"
        buttonLabel="Add meeting"
      />
    </div>
  );
};

export default Meetings;
