import { ChartDataTag } from '@aminsights/contract';
import { Tooltip } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '@/assets/svg/icons/icon-close.svg';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import style from './style.module.less';

interface LegendCardBaseProps {
  label: string;
  tooltip: string;
  color: string;
  date: string;
  id?: string;
  dataTag?: ChartDataTag;
  onRemove?: (id: string, dataTag?: ChartDataTag) => void;
}

export interface LegendCardProps extends LegendCardBaseProps {
  targetLink?: string;
  superTag?: string;
  cardClasses?: {
    backgound: string;
    weight: string;
  };
}

export interface BaseCardDetails extends LegendCardBaseProps {
  id: string;
  isin?: string;
  isActive?: boolean;
}

const LegendCard: React.FCWithChild<LegendCardProps> = ({
  color,
  label,
  tooltip,
  children,
  targetLink,
  cardClasses,
  superTag,
  dataTag,
  onRemove,
  id,
  date,
}) => {
  const wrapLabelWithLink = (label: string) => {
    const content = (
      <>
        <p>{label}</p>
        <div className="flex">
          <div className="min-w-14 text-end">{children}</div>
        </div>
      </>
    );

    return targetLink ? (
      isTablet && firstTap ? (
        <div
          className={cx(
            style.fundDetails,
            'text-xs text-neutral text-normal',
            cardClasses?.weight,
          )}
        >
          {content}
        </div>
      ) : (
        <Link
          to={targetLink || ''}
          className={cx(
            style.fundDetails,
            'text-xs text-neutral text-normal',
            cardClasses?.weight,
          )}
        >
          {content}
        </Link>
      )
    ) : (
      <div className={style.fundDetails}>
        <p
          className={cx(
            'text-xs text-neutral text-normal flex',
            cardClasses?.weight,
          )}
        >
          {label}
        </p>
        <div className="flex">{children}</div>
      </div>
    );
  };

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [firstTap, setFirsTap] = useState(true);
  const screenWidthMode = getScreenWidthMode();
  const isTablet = screenWidthMode[ScreenWidthEnum.MaxLg];

  const handleTooltipVisibleChange = (
    visible: boolean | ((prevState: boolean) => boolean),
  ) => {
    setTooltipVisible(visible);
    if (!visible) {
      setFirsTap(true);
    }
    if (firstTap) {
      setTooltipVisible(true);
      setFirsTap(false);
    }
  };

  const renderSuperTag = () => {
    return superTag ? (
      <>
        {' ('}
        <b>{superTag}</b>
        {')'}
      </>
    ) : null;
  };

  const mainComponentPart = () => (
    <>
      <Tooltip
        align={{ offset: [0, 6] }}
        placement="bottom"
        trigger={isTablet ? 'click' : 'hover'}
        open={tooltipVisible}
        onVisibleChange={handleTooltipVisibleChange}
        title={
          <p>
            {tooltip}
            {renderSuperTag()}
            {date && (
              <>
                <br />
                As of {date}
              </>
            )}
          </p>
        }
        overlayInnerStyle={{ borderRadius: 4, fontSize: 12 }}
        arrowPointAtCenter
      >
        <div
          className={cx(
            'flex items-center h-4 rounded relative',
            style.legendCard,
            cardClasses?.backgound,
          )}
        >
          <div className="w-6">
            <div
              className={cx('w-2 h-2 mx-2 rounded-full', style.circle)}
              style={{ background: color }}
            />
          </div>

          <div className={style.label}>{wrapLabelWithLink(label)}</div>
          {onRemove && (
            <span
              className="hover:text-red-500 text-gray-400 cursor-pointer pl-2"
              onClick={() => {
                if ((!isTablet || !firstTap) && id) {
                  onRemove?.(id, dataTag);
                }
              }}
            >
              <CloseIcon className="h-2.5 w-2.5 fill-[#3B3C53] mr-1.5" />
            </span>
          )}
        </div>
      </Tooltip>
    </>
  );

  return mainComponentPart();
};

export default LegendCard;
