import { EMPTY_DATA_POINT } from '@aminsights/shared';
import Decimal from 'decimal.js';

/**
 * Function thats takes a number or undefined and returns a string with the number rounded to the precision specified.
 * This is mainly a conveinience method to avoid having to check for undefined values before calling toFixed.
 */
export const roundOrEmpty = (
  value: string | number | boolean | null | undefined,
  precision = 2,
  symbol = '',
): string => {
  if (!value) {
    return EMPTY_DATA_POINT;
  }
  if (typeof value === 'boolean') {
    // For convenience, we don't want to show boolean values in the UI
    return EMPTY_DATA_POINT;
  }
  const valueDecimal = new Decimal(value);
  const preciseValue = valueDecimal.toDecimalPlaces(precision);

  const preciseValueStr = preciseValue.toString();
  const [whole, fraction = ''] = preciseValueStr.split('.');

  let finalValue = whole;
  if (precision > 0) {
    const paddedFraction = fraction.padEnd(precision, '0');
    finalValue = `${whole}.${paddedFraction}`;
  }
  if (symbol) {
    return `${finalValue}${symbol}`;
  }
  return finalValue;
};
