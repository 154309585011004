import { APP_ROUTE_CHARTS } from '@aminsights/shared';
import { useQueryClient } from '@tanstack/react-query';
import { Tabs } from 'antd';
import cx from 'classnames';
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import {
  CHARTS,
  CHARTS_ABSOLUTE_WITH_PORTFOLIO,
  CHARTS_RELATIVE_WITH_PORTFOLIO,
} from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import BenchmarksDropdown from '@/partials/BenchmarksDropdown';
import { RipsReturnTypeProvider } from '@/partials/Charts/Context';
import { DatePickerProvider } from '@/partials/DatePicker';
import ChartingToolRouter from '@/router/Charts';
import { CHARTS_ROUTES, EChartsRoutes, IRoute } from '@/router/routes';
import { toCamelCase } from '@/utils/toCamelCase';

import BasePageWithMetadata from '../BasePageWithMetadata';
import ChartingContextProvider from './components/ChartingContext';
import ChartSearchBox from './components/ChartSearchbox';
import styles from './style.module.less';
import useChartTrustFundsFromSearch from './utils/useChartFundsFromSearch';

type TTabDetails = IRoute & {
  label: string;
};

const tabs: TTabDetails[] = CHARTS_ROUTES.map(r => ({
  ...r,
  label: (() => {
    switch (r.path) {
      case EChartsRoutes.ABSOLUTE:
        return 'Absolute';
      case EChartsRoutes.RELATIVE:
        return 'Relative';
      case EChartsRoutes.PREMIUM_DISCOUNT:
        return 'Premium/Discount';
      case EChartsRoutes.RISK_RETURN:
        return 'Risk/Return';
      default:
        return '';
    }
  })(),
}));

const ChartsPage = () => {
  const {
    data: benchmarkOptionsResponse,
    isLoading: isBenchmarkOptionsLoading,
  } = useBenchmarkOptions();
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const activeTab = location.pathname.slice(APP_ROUTE_CHARTS.length + 2);
  const setActiveTab = (target: string) => {
    const searchParams = new URLSearchParams(location.search);
    history.push({ pathname: target, search: searchParams.toString() });
  };
  const { benchmark, setBenchmark } = useChartTrustFundsFromSearch();

  const metaTitle = useMemo(() => {
    return `Charts - ${tabs.find(t => t.path === activeTab)?.label}`;
  }, [activeTab]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [CHARTS, CHARTS_ABSOLUTE_WITH_PORTFOLIO],
      });
      queryClient.removeQueries({
        queryKey: [CHARTS, CHARTS_RELATIVE_WITH_PORTFOLIO],
      });
    };
  }, []);

  return (
    <DatePickerProvider preserveState={true}>
      <RipsReturnTypeProvider>
        <ChartingContextProvider>
          <BasePageWithMetadata title={metaTitle}>
            <div className={cx(styles['section-charts'], 'lg:px-4 pt-4')}>
              <h3
                className={cx('px-4 pt-2 text-xl font-bold mb-4 lg:px-0')}
                data-test-id="chartingToolPageTitle"
              >
                Charting
              </h3>
              <div
                className={cx(
                  'pb-6 mb-6 bg-white lg:rounded-lg',
                  styles['section-charts__card'],
                )}
              >
                <div className="mb-4">
                  <Tabs
                    activeKey={activeTab}
                    onChange={e => setActiveTab(e)}
                    items={tabs.map(tab => ({
                      label: (
                        <div
                          data-test-id={toCamelCase(`chartingTool${tab.label}`)}
                        >
                          {tab.label}
                        </div>
                      ),
                      key: tab.path,
                    }))}
                  />
                </div>

                <div className="grid pb-4 px-4 items-center md:grid-cols-2 gap-y-4 gap-x-8 border-b border-[#CCCCD0]">
                  <ChartSearchBox activeTab={activeTab} />
                  <BenchmarksDropdown
                    dataTestId="chartingToolBenchmark"
                    placeholder="Select benchmark"
                    className="w-full !h-10"
                    value={benchmark}
                    benchmarkOptions={benchmarkOptionsResponse || []}
                    onSelect={selectedValue => setBenchmark(selectedValue)}
                    onClear={() => setBenchmark('')}
                    isOptionsLoading={isBenchmarkOptionsLoading}
                    hideCustomOptions
                  />
                </div>
                <div className="px-4 py-6 section-watchlist-inner">
                  <ChartingToolRouter />
                </div>
              </div>
            </div>
          </BasePageWithMetadata>
        </ChartingContextProvider>
      </RipsReturnTypeProvider>
    </DatePickerProvider>
  );
};

export default ChartsPage;
