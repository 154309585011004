import {
  ChartDataResponseLegendData,
  ScatterPlotResponseLegendData,
} from '@aminsights/contract';
import { generateChartColors } from '@aminsights/shared';
import { useMemo } from 'react';

import { useMultipleFundsByIsins } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

export interface UseChartColorsBaseProps<T> {
  legend: T[];
  retainSortOrder?: boolean;
}
interface UseChartColorsByIsinsProps<T> extends UseChartColorsBaseProps<T> {
  isins: string[];
}
interface UseChartColorsByIdsProps<T> extends UseChartColorsBaseProps<T> {
  ids: string[];
}
export interface UseChartsColorsByIsinsAndPortfoliosProps<T>
  extends UseChartColorsBaseProps<T> {
  isins: string[];
  // Format should be {id}_{name}
  portfolioLegendIds?: string[];
}

type LegendData = ScatterPlotResponseLegendData | ChartDataResponseLegendData;

const useChartColors = <T extends LegendData>({
  isins,
  legend,
  retainSortOrder,
}: UseChartColorsByIsinsProps<T>) => {
  const { data: funds } = useMultipleFundsByIsins(isins);
  const sortedIsins = useMemo(() => {
    return retainSortOrder
      ? isins
      : funds
          ?.sort((a, b) => a.fundName.localeCompare(b.fundName))
          .map(f => f.shareClassDetails.isin) || [];
  }, [funds, isins]);
  const colors = useMemo(
    () => generateChartColors({ legendData: legend, sortedIsins }),
    [legend, sortedIsins],
  );
  return colors;
};

export const useChartColorsById = <T extends LegendData>({
  ids,
  legend,
}: UseChartColorsByIdsProps<T>) => {
  const colors = useMemo(
    () => generateChartColors({ legendData: legend, ids }),
    [legend, ids],
  );
  return colors;
};

export const useChartColorsWithIsinsAndPortfolio = <T extends LegendData>({
  isins,
  portfolioLegendIds,
  legend,
}: UseChartsColorsByIsinsAndPortfoliosProps<T>) => {
  const { data: funds } = useMultipleFundsByIsins(isins);
  const sortedIds = useMemo(() => {
    const portfolioLabels = legend
      .filter(l => portfolioLegendIds?.find(f => l.id?.includes(f)))
      .map(p => {
        const idSplit = p.id?.split('_') || [];
        return {
          label: idSplit[1] || '',
          id: p.id || '',
        };
      });
    const fundLabels = (funds || []).map(f => ({
      id: legend.find(l => l.isin === f.shareClassDetails.isin)?.id || '',
      label: f.fundName,
    }));
    const sortedLabels = [...portfolioLabels, ...fundLabels].sort((a, b) =>
      a.label.localeCompare(b.label),
    );
    const sortedIds = sortedLabels
      .map(({ id }) => id)
      .filter(Boolean) as string[];
    return sortedIds;
  }, [funds, isins, portfolioLegendIds, legend]);
  const colors = useMemo(
    () => generateChartColors({ legendData: legend, ids: sortedIds }),
    [sortedIds, legend],
  );
  return colors;
};

export default useChartColors;
