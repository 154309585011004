import { Slider } from 'antd';
import cx from 'classnames';

import Accordion, { Panel } from '@/components/Accordion';
import { SLIDER_MAX } from '@/constants';
import RenderStyleBox from '@/partials/RenderStyleBox';

import {
  AVG_CREDIT_SLIDER_MAX,
  avgCreditMarks,
  bondsInitialState,
  modifiedDurationMarks,
  YIELD_MATURITY_SLIDER_MAX,
  yieldMaturityMarks,
} from '../hooks/bondsSubState';
import useExploreFilters from '../hooks/useExploreFilters';
import { adjustMarksToAvoidDuplicates } from '../utils/adjustMarksToAvoidDuplicates';
import { getChangedMarkValues } from '../utils/getChangedMarkValues';
import { getRangeSliderClassName } from '../utils/getRangeSliderClassName';
import lengthOrEmpty from '../utils/lengthOrEmpty';
import style from './style.module.less';

const BondsFilterPanels = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();

  const bondsFilters = tentativeFilters.bondsFilters ?? bondsInitialState;

  const [modifiedDurationRangeMin, modifiedDurationRangeMax] =
    bondsFilters?.modifiedDurationRange ??
    bondsInitialState.modifiedDurationRange;
  const [yieldMaturityRangeMin, yieldMaturityRangeMax] =
    tentativeFilters.bondsFilters?.yieldMaturityRange ??
    bondsInitialState.yieldMaturityRange;

  const [avgCreditQualityRangeMin, avgCreditQualityRangeMax] =
    tentativeFilters.bondsFilters?.avgCreditQualityRange ??
    bondsInitialState.avgCreditQualityRange;

  const avgCreditQuality = [avgCreditQualityRangeMin, avgCreditQualityRangeMax];

  return (
    <div className="modal-more-filters">
      <Accordion defaultActiveKey="1">
        <Panel
          header="Style"
          dataTestId={'bondsLabelStyle'}
          key="1"
          extra={lengthOrEmpty(tentativeFilters.bondsFilters?.bondsStyle)}
        >
          <div className="relative">
            <div className="flex">
              <RenderStyleBox
                isFixedIncome
                isBoxClickable
                onSelectedBox={(selected: number[]) => {
                  const filters = getMutableFilters();
                  filters.bondsFilters = {
                    ...filters.bondsFilters,
                    bondsStyle: selected.length > 0 ? selected : undefined,
                  };
                  updateTentativeFilters(filters);
                }}
                isClear={
                  !tentativeFilters.bondsFilters?.bondsStyle ||
                  tentativeFilters.bondsFilters?.bondsStyle?.length === 0
                }
                selectedBox={tentativeFilters.bondsFilters?.bondsStyle}
              />
            </div>
            <p className="font-normal text-xs w-max absolute top-[40%] transform rotate-90 sm:left-[16.2rem] left-[14.2rem] text-neutral">
              Credit Risk
            </p>
            <p className="text-xs font-normal pl-24 sm:pl-[7.1rem] text-neutral">
              Duration
            </p>
          </div>
        </Panel>
        <Panel
          header="Modified Duration"
          dataTestId={'bondsLabelModifiedDuration'}
          key="2"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.bondsFilters?.modifiedDurationRange,
                modifiedDurationMarks,
              )}
            </span>
          }
        >
          <div
            className={style['range-slider']}
            data-test-id={'bondsSliderModifiedDuration'}
          >
            <Slider
              className={
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.bondsFilters?.modifiedDurationRange,
                  )
                ]
              }
              tooltip={{ open: false }}
              range
              step={20}
              max={SLIDER_MAX}
              marks={modifiedDurationMarks}
              value={[modifiedDurationRangeMin, modifiedDurationRangeMax]}
              onChange={value => {
                const filters = getMutableFilters();
                filters.bondsFilters = {
                  ...filters.bondsFilters,
                  modifiedDurationRange: adjustMarksToAvoidDuplicates(
                    value,
                    modifiedDurationMarks,
                  ),
                };
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </Panel>
        <Panel
          header="Yield to Maturity"
          dataTestId={'bondsLabelYieldToMaturity'}
          key="3"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.bondsFilters?.yieldMaturityRange,
                yieldMaturityMarks,
              )}
            </span>
          }
        >
          <div
            className={style['range-slider']}
            data-test-id={'bondsSliderYieldToMaturity'}
          >
            <Slider
              className={
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.bondsFilters?.yieldMaturityRange,
                  )
                ]
              }
              tooltip={{ open: false }}
              range
              step={33}
              max={YIELD_MATURITY_SLIDER_MAX}
              marks={yieldMaturityMarks}
              value={[yieldMaturityRangeMin, yieldMaturityRangeMax]}
              onChange={value => {
                const filters = getMutableFilters();
                filters.bondsFilters = {
                  ...filters.bondsFilters,
                  yieldMaturityRange: adjustMarksToAvoidDuplicates(
                    value,
                    yieldMaturityMarks,
                  ),
                };
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </Panel>
        <Panel
          header="Average Credit Quality"
          dataTestId={'bondsLabelAverageCreditQuality'}
          key="4"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.bondsFilters?.avgCreditQualityRange,
                avgCreditMarks,
              )}
            </span>
          }
        >
          <div
            className={cx(
              style['range-slider'],
              style['range-slider-credit-quality'],
            )}
            data-test-id={'bondsSliderAverageCreditQuality'}
          >
            <Slider
              className={cx(
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.bondsFilters?.avgCreditQualityRange,
                  )
                ],
                style['slider-first-option-long'],
              )}
              tooltip={{ open: false }}
              range
              step={15}
              max={AVG_CREDIT_SLIDER_MAX}
              marks={avgCreditMarks}
              value={avgCreditQuality}
              onChange={value => {
                const filters = getMutableFilters();
                filters.bondsFilters = {
                  ...filters.bondsFilters,
                  avgCreditQualityRange: adjustMarksToAvoidDuplicates(
                    value,
                    avgCreditMarks,
                  ),
                };
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </Panel>
      </Accordion>
    </div>
  );
};

export default BondsFilterPanels;
