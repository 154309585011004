import {
  TREATMENT_AI_SEARCH,
  TREATMENT_APP_LIMITED_ACCESS,
  TREATMENT_NAME_BLOOMBERG_BENCHMARKS,
  TREATMENT_NAME_CLARITY,
  TREATMENT_NAME_COMPUTED_RISK_DETAILS,
  TREATMENT_NAME_FUND_DETAILS_ACTIONS,
  TREATMENT_NAME_WEEKLY_UPDATE,
  TREATMENT_RESEARCH,
  TreatmentValues,
} from '@aminsights/shared';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { getConfigValue } from '@/constants';
import { useScript } from '@/utils/useScript';

export type FeatureSwitchState = {
  isInitialised: boolean;
  isClarityEnabled: boolean;
  isFundDetailsActionsTreatmentEnabled: boolean;
  isWeeklyUpdateTreatementEnabled: boolean;
  isComputedRiskDetailsTreatmentEnabled: boolean;
  isAiSearchEnabled?: boolean;
  isAppLimitedAccessEnabled?: boolean;
  isResearchTabEnabled?: boolean;
  isBloombergBenchmarksEnabled?: boolean;
};

const initialState: FeatureSwitchState = {
  isClarityEnabled: false,
  isInitialised: false,
  isFundDetailsActionsTreatmentEnabled: false,
  isWeeklyUpdateTreatementEnabled: false,
  isComputedRiskDetailsTreatmentEnabled: false,
  isAiSearchEnabled: false,
  isAppLimitedAccessEnabled: false,
  isResearchTabEnabled: false,
  isBloombergBenchmarksEnabled: false,
};

export enum FEATURE_SWITCH_ACTIONS {
  SET_SPLITS = 'feature-switch:set-splits',
}

type FeatureSwitchDispatchActions = {
  type: FEATURE_SWITCH_ACTIONS;
  payload: FeatureSwitchState;
};

type FeatureSwitchContextProps = {
  state: FeatureSwitchState;
  dispatch: React.Dispatch<FeatureSwitchDispatchActions>;
};

const FeatureSwitchContext = createContext<FeatureSwitchContextProps>({
  state: initialState,
  dispatch: () => ({}),
});

const reducer = (
  state: FeatureSwitchState,
  action: FeatureSwitchDispatchActions,
): FeatureSwitchState => {
  switch (action.type) {
    case FEATURE_SWITCH_ACTIONS.SET_SPLITS: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        isInitialised: true,
      };
    }
    default:
      return state;
  }
};

const FeatureSwitchProvider: React.FCWithChild = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { treatments, isReady } = useSplitTreatments({
    names: [
      TREATMENT_NAME_CLARITY,
      TREATMENT_NAME_FUND_DETAILS_ACTIONS,
      TREATMENT_NAME_WEEKLY_UPDATE,
      TREATMENT_NAME_COMPUTED_RISK_DETAILS,
      TREATMENT_APP_LIMITED_ACCESS,
      TREATMENT_AI_SEARCH,
      TREATMENT_RESEARCH,
      TREATMENT_NAME_BLOOMBERG_BENCHMARKS,
    ],
  });

  useEffect(() => {
    if (isReady && !state.isInitialised) {
      const clarityTreatment = treatments[TREATMENT_NAME_CLARITY];
      const fundDetailsActionsTreatment =
        treatments[TREATMENT_NAME_FUND_DETAILS_ACTIONS];
      const weeklyUpdateTreatment = treatments[TREATMENT_NAME_WEEKLY_UPDATE];
      const computedRiskDetailsTreatment =
        treatments[TREATMENT_NAME_COMPUTED_RISK_DETAILS];
      const aiSearchTreatment = treatments[TREATMENT_AI_SEARCH];
      const appLimitedAccessTreatment =
        treatments[TREATMENT_APP_LIMITED_ACCESS];
      const researchTreatment = treatments[TREATMENT_RESEARCH];
      const bloombergBenchmarksTreatment =
        treatments[TREATMENT_NAME_BLOOMBERG_BENCHMARKS];

      dispatch({
        type: FEATURE_SWITCH_ACTIONS.SET_SPLITS,
        payload: {
          isInitialised: true,
          isClarityEnabled: clarityTreatment?.treatment === TreatmentValues.On,
          isFundDetailsActionsTreatmentEnabled:
            fundDetailsActionsTreatment?.treatment === TreatmentValues.On,
          isWeeklyUpdateTreatementEnabled:
            weeklyUpdateTreatment?.treatment === TreatmentValues.On,
          isComputedRiskDetailsTreatmentEnabled:
            computedRiskDetailsTreatment?.treatment === TreatmentValues.On,
          isAiSearchEnabled:
            aiSearchTreatment?.treatment === TreatmentValues.On,
          isAppLimitedAccessEnabled:
            appLimitedAccessTreatment?.treatment === TreatmentValues.On,
          isResearchTabEnabled:
            researchTreatment?.treatment === TreatmentValues.On,
          isBloombergBenchmarksEnabled:
            bloombergBenchmarksTreatment?.treatment === TreatmentValues.On,
        },
      });
    }
  }, [isReady, treatments]);

  useScript(
    `/clarity.${getConfigValue('REACT_APP_NODE_ENV')}.js`,
    'ms_clarity',
    !state.isClarityEnabled,
  );

  return (
    <FeatureSwitchContext.Provider value={{ state, dispatch }}>
      {state.isInitialised ? <>{children}</> : null}
    </FeatureSwitchContext.Provider>
  );
};

export { FeatureSwitchProvider };

export const useFeatureSwitchContext = (): FeatureSwitchContextProps => {
  return useContext(FeatureSwitchContext);
};

export default FeatureSwitchContext;
