import './style.less';

import { buildFundDetailsPath, WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { Tabs, TabsProps } from 'antd';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PageResults } from '@/components';
import { WATCHLIST_TABS } from '@/constants';
import useTabsToShowOnWatchlist from '@/hooks/query-hooks/watchlist-hooks/useTabsToShowOnWatchlist';
import ExploreSearchV2, {
  renderOptions,
} from '@/partials/ExploreSearchBox/ExploreSearchBoxV2';
import { useSearchBox } from '@/partials/ExploreSearchBox/useExploreSearchbox';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import BasePageWithMetadata from '../BasePageWithMetadata';
import { EmptySearch } from './components/EmptySearch';
import ExploreFilters from './components/ExploreFilters';
import ExploreTable from './components/ExploreTable';
import { useExploreDefaultFiltersQuery, useExploreQuery } from './context';
import useExploreFilters from './hooks/useExploreFilters';
import { convertParamsToState } from './useProvideExplore';
import areFiltersApplied from './utils/filterComparisonHelpers';

const Explore: React.FCWithChild = () => {
  const location = useLocation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState<WATCHLIST_TAB_KEY>(
    WATCHLIST_TAB_KEY.SUMMARY,
  );
  const { data: tabsToShow } = useTabsToShowOnWatchlist('all');

  const screenWidthMode = getScreenWidthMode();

  const {
    filters,
    composeStateFromSearchString,
    emptyFilters,
    getMutableFilters,
    updateTentativeFilters,
    syncFilters,
  } = useExploreFilters();

  const searchParams = useMemo(() => {
    // For some reason location from useLocation is not updated. Maybe because of how we update the url in zustand
    return new URLSearchParams(window.location.search);
  }, [filters, location.search]);

  useEffect(() => {
    composeStateFromSearchString();
    return () => emptyFilters();
  }, []);

  useEffect(() => {
    const { pathname } = location;
    const currentTab = [...(tabsToShow ?? [])].find(word =>
      pathname.includes(word),
    ) as WATCHLIST_TAB_KEY;
    setActiveTab(currentTab);
  }, [tabsToShow]);

  const { data: defaultfiltersOptions, isLoading: isLoadingDefaultFilters } =
    useExploreDefaultFiltersQuery();

  const searchBoxOpts = useSearchBox({
    defaultValue: filters.term,
  });

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useExploreQuery({
      queryParams: convertParamsToState(
        searchParams,
        {
          categories: defaultfiltersOptions?.categories,
          iaSectors: defaultfiltersOptions?.iaSectors,
          providers: defaultfiltersOptions?.providerNames || [],
        },
        true,
      ),
      enabled: !isLoadingDefaultFilters && areFiltersApplied(filters),
    });
  const totalCount = data?.pages?.[0]?.data.totalCount || 0;

  const onNavigateTab = (tab?: string) => {
    const filters = getMutableFilters();
    filters.page = undefined;
    updateTentativeFilters(filters);
    syncFilters();

    let currentTab = tab;
    if (!tab) currentTab = WATCHLIST_TAB_KEY.SUMMARY;
    setActiveTab(currentTab as WATCHLIST_TAB_KEY);
  };

  const tabItems = useMemo(() => {
    const tabs: TabsProps['items'] = [];

    WATCHLIST_TABS.filter(wt => {
      if (wt.key === WATCHLIST_TAB_KEY.CORRELATION_MATRIX) return false;
      return tabsToShow?.some(tts => tts === wt.key);
    }).forEach(tab =>
      tabs.push({
        ...tab,
        children: (
          <ExploreTable
            fetchNextPage={() => fetchNextPage()}
            currentTab={tab.key}
            hasNextPage={hasNextPage}
            data={data?.pages.flatMap(p => p.data.data || []) || []}
            loading={isLoading}
            loadingNextPage={isFetchingNextPage}
            showDropdown={tab.key === WATCHLIST_TAB_KEY.RISK}
            activeTab={activeTab}
          />
        ),
      }),
    );
    return tabs;
  }, [activeTab, tabsToShow, data, isLoading, hasNextPage, isFetchingNextPage]);

  return (
    <BasePageWithMetadata title={'Explore'}>
      <section className={cx('section-explore', 'pt-4')}>
        <h2 className="px-4 pt-2 mb-4 lg:mb-0 text-xl font-bold text-darkest">
          Explore
        </h2>
        <div className="flex flex-row lg:p-4">
          <div className="pt-1 mb-6 section-explore-wrapper bg-white lg:rounded-lg">
            <div className="section-explore-head">
              <div className="section-explore-head__search-bar">
                <div
                  className={`section-explore-head__search-input ${
                    screenWidthMode[ScreenWidthEnum.MaxLg]
                      ? '[&_.ant-select-selector]:!rounded-[4px]'
                      : '[&_.ant-select-selector]:!rounded-[2px]'
                  }`}
                >
                  <ExploreSearchV2
                    dataTestId="exploreSearchBox"
                    options={searchBoxOpts.allSuggestions.map(opt =>
                      renderOptions({
                        value: opt.shareClassDetails.isin,
                        title: opt.shareClassDetails.code,
                        highlightTerm: searchBoxOpts.searchValue,
                        primaryShareClass: opt.primaryShareClass,
                      }),
                    )}
                    value={searchBoxOpts.searchValue}
                    onSelect={value => {
                      history.push(buildFundDetailsPath(value));
                    }}
                    onChange={searchBoxOpts.onChange}
                    onSubmit={searchBoxOpts.onSearchResults}
                    loading={searchBoxOpts.isLoading}
                  />
                </div>
                <div id="mobile-filters-portal"></div>
              </div>
              <ExploreFilters />
            </div>
            {!totalCount && !isLoading ? (
              <EmptySearch
                title="Looking for a fund or investment trust?"
                subTitle="Use the search bar or apply filters."
              />
            ) : (
              <div className="section-explore-body">
                {/* TODO: need to share this component: found in watchlist and explore */}
                <Tabs
                  activeKey={activeTab}
                  onChange={onNavigateTab}
                  items={tabItems}
                  tabBarExtraContent={
                    <div className="flex items-center">
                      <PageResults totalCount={totalCount} />
                      <div id="dropdown-portal-desktop" className="ml-4"></div>
                    </div>
                  }
                  destroyInactiveTabPane
                  renderTabBar={(defaultProps, DefaultComponent) => (
                    <>
                      <DefaultComponent {...defaultProps} />
                      <div className="flex justify-end items-center w-full p-4 bg-white md:hidden">
                        <PageResults totalCount={totalCount} />
                        <div id="dropdown-portal-mobile" className="ml-4"></div>
                      </div>
                    </>
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </BasePageWithMetadata>
  );
};

export default Explore;
