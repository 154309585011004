import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Button, HorizontalScroll, SummaryCard } from '@/components';
import { summaryCardMapping } from '@/components/Card/SummaryCard/types';
import useDashboardSummaryCards from '@/hooks/query-hooks/dashboard-hooks/useDashboardSummaryCards';

import style from './style.module.less';

const Summary = () => {
  const refScroll = useRef<HTMLDivElement>(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [scrollSteps, setScrollSteps] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(10);

  const { data, isLoading } = useDashboardSummaryCards();
  const summaryCards = data;

  useEffect(() => {
    if (refScroll.current && !isLoading && data) {
      const hasScroll =
        refScroll.current.scrollWidth > refScroll.current.clientWidth;
      setHasScrollbar(hasScroll);
      setScrollSteps(refScroll.current?.getBoundingClientRect().width);
    }
  }, [
    refScroll.current?.scrollWidth,
    refScroll.current?.getBoundingClientRect().width,
    data,
    isLoading,
  ]);

  const onClickScroll = (scroll: number) => {
    if (refScroll?.current) {
      setScrollLeft(scroll + (refScroll?.current?.scrollLeft || 0));
      refScroll.current.scrollTo({
        top: 0,
        left: scroll + refScroll?.current.scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div>
      <div className="pl-4 flex flex-row justify-between pt-2">
        <h2 className="text-xl mb-4 font-bold text-darkest">Dashboard</h2>
        <div className="flex justify-end mb-4 gap-x-1">
          {(data?.length ?? 0) !== 0 && hasScrollbar && (
            <>
              <Button
                className={cx('!rounded-full !text-xs !border-0 !h-8 !w-8')}
                type="default"
                onClick={() => onClickScroll(-scrollSteps)}
                icon={<LeftOutlined className={style['icon-bold']} />}
              />
              <Button
                className={cx('!rounded-full !text-xs !border-0 !h-8 !w-8')}
                onClick={() => onClickScroll(scrollSteps)}
                icon={<RightOutlined className={style['icon-bold']} />}
              />
            </>
          )}
        </div>
      </div>
      <HorizontalScroll
        hideScrollbars
        horizontal
        ref={refScroll}
        className="snap-x snap-mandatory pl-4 relative pb-4 -mb-4 scroll-pl-4"
      >
        <div
          className={cx(
            'h-[122px] opacity-0 shrink-0 w-4 sticky -left-4 top-0',
            'bg-[#f0f2f5] z-1 transition-opacity shadow-[0_20px_#f0f2f5]',
            scrollLeft > 10 ? '!opacity-100' : 'duration-500 delay-500',
            !hasScrollbar && 'hidden',
          )}
        />
        {Array.from(summaryCardMapping.keys()).map(cardType => {
          const summaryCard = summaryCards?.find(
            card => card.cardType === cardType,
          );
          return (
            <SummaryCard
              key={cardType}
              cardItem={summaryCard}
              loading={isLoading}
            />
          );
        })}
      </HorizontalScroll>
    </div>
  );
};

export default Summary;
