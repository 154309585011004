import { Button, Select } from 'antd';
import { useState } from 'react';

import { ReactComponent as TeamIcon } from '@/assets/svg/research/team.svg';

import EmptyState from '../EmptyState';

const Analyst = () => {
  const [showAddAnalyst, setShowAddAnalyst] = useState(false);
  const [hasAnalyst, setHasAnalyst] = useState(false);
  return (
    <div className="flex bg-white flex-col gap-4 p-4 rounded-lg">
      <div className="flex justify-between">
        <span className="text-sm font-semibold text-darkest">Analyst</span>
        {hasAnalyst && (
          <Button
            className="w-auto px-4 py-1.5 text-xs not-italic font-medium rounded"
            type="primary"
            onClick={() => setShowAddAnalyst(true)}
          >
            Add analyst
          </Button>
        )}
      </div>
      {!showAddAnalyst && (
        <EmptyState
          icon={<TeamIcon />}
          onClick={() => setShowAddAnalyst(true)}
          title="You haven’t assigned analyst to this fund"
          buttonLabel="Add analyst"
        />
      )}
      {showAddAnalyst && (
        <section>
          <div className="flex-1 flex flex-col">
            <Select
              placement="bottomLeft"
              mode="multiple"
              data-test-id="researchAnalystSelect"
              placeholder="Ex. John, James,..."
              suffixIcon={<></>}
            />
            <p className="text-xs text-neutral-400 mt-1">
              Share to one or more recipients, separated by commas.
            </p>
          </div>
          <div className="flex gap-x-2 flex-row justify-center mt-4">
            <Button
              className="w-auto px-6 py-2 text-xs not-italic font-medium rounded border border-primary border-solid text-primary"
              type="text"
              onClick={() => setShowAddAnalyst(false)} // Example onClick handler to cancel
            >
              Cancel
            </Button>
            <Button
              className="w-auto px-6 py-2 text-xs not-italic font-medium rounded"
              type="primary"
              onClick={
                () => {
                  setHasAnalyst(true);
                } /* Add other save logic here */
              }
            >
              Save
            </Button>
          </div>
        </section>
      )}
    </div>
  );
};

export default Analyst;
