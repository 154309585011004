import { NoteBody, NoteCategory } from '@aminsights/contract';
import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import React, { FC, useState } from 'react';

import { ReactComponent as AutoGraphRoundedIcon } from '@/assets/svg/research/menu/AutoGraphRounded.svg';
import { ReactComponent as CheveronDownIcon } from '@/assets/svg/research/menu/Cheveron-down.svg';
import { ReactComponent as CogDefaultIcon } from '@/assets/svg/research/menu/Cog-default.svg';
import { ReactComponent as LightBulbIcon } from '@/assets/svg/research/menu/Light-bulb.svg';
import { ReactComponent as PeopleOutlineOutlinedIcon } from '@/assets/svg/research/menu/PeopleOutlineOutlined.svg';
import { ReactComponent as PieChartOutlinedIcon } from '@/assets/svg/research/menu/PieChartOutlined.svg';
import { ReactComponent as PlusIcon } from '@/assets/svg/research/menu/Plus.svg';
import { ReactComponent as StickyNoteIcon } from '@/assets/svg/research/menu/StickyNote.svg';

import NoteModal from './NoteModal';

interface MenuItemProps {
  icon: React.ReactNode;
  label: string;
}

const MenuItem: FC<MenuItemProps> = ({ icon, label }) => (
  <span className="!w-[unset] flex items-center justify-start">
    {icon}
    <label className="text-neutral-700 text-sm ml-2">{label}</label>
  </span>
);

const iconMapping: Record<NoteCategory, React.ReactNode> = {
  [NoteCategory.Summary]: <StickyNoteIcon className="mr-2 text-neutral-700" />,
  [NoteCategory.People]: (
    <PeopleOutlineOutlinedIcon className="mr-2 text-neutral-700" />
  ),
  [NoteCategory.Philosophy]: (
    <LightBulbIcon className="mr-2 text-neutral-700" />
  ),
  [NoteCategory.Process]: <CogDefaultIcon className="mr-2 text-neutral-700" />,
  [NoteCategory.Portfolio]: (
    <PieChartOutlinedIcon className="mr-2 text-neutral-700" />
  ),
  [NoteCategory.Performance]: (
    <AutoGraphRoundedIcon className="mr-2 text-neutral-700" />
  ),
};

interface DropdownMenuWithModalProps {
  onSubmit: (note: NoteBody) => void;
}

const DropdownMenuWithModal: FC<DropdownMenuWithModalProps> = ({
  onSubmit,
}) => {
  const [selectedContentType, setSelectedContentType] = useState<NoteCategory>(
    NoteCategory.Summary,
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const menuItemsData = Object.entries(NoteCategory).map(([key, value]) => ({
    key: value,
    icon: iconMapping[value as NoteCategory],
    label: key,
  }));

  const items = menuItemsData.map(({ key, icon, label }) => ({
    key,
    label: <MenuItem icon={icon} label={label} />,
    onClick: () => {
      setSelectedContentType(key as NoteCategory);
      setShowModal(true);
    },
  }));

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleNoteSubmit = (note: NoteBody) => {
    setShowModal(false);
    onSubmit(note);
  };

  return (
    <>
      <NoteModal
        modalInfo={{
          title: 'Note',
          contentType: selectedContentType,
          primaryActionDisabled: false,
        }}
        isVisible={showModal}
        toggleModal={() => setShowModal(false)}
        onSubmit={handleNoteSubmit}
      />
      <Dropdown
        menu={{ items }}
        placement="bottom"
        autoAdjustOverflow={false}
        overlayClassName={cx(
          'bg-white rounded border border-[#F2F2F5] border-solid p-2',
          'shadow-dropdown',
          '[&>.ant-dropdown-menu]:p-0',
          '[&>.ant-dropdown-menu]:border-none',
        )}
        trigger={['click']}
        onOpenChange={setIsDropdownOpen}
      >
        <Button
          type="primary"
          className={cx('flex items-center gap-2 px-4 py-2.5 h-10', {
            'bg-[#005FBF]': isDropdownOpen,
          })}
        >
          <PlusIcon className="text-xs" />
          Add a note
          <CheveronDownIcon className="text-xs" />
        </Button>
      </Dropdown>
    </>
  );
};

export default DropdownMenuWithModal;
