import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { useContext } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Option } from '@/components';
import useTabsToShowOnWatchlist from '@/hooks/query-hooks/watchlist-hooks/useTabsToShowOnWatchlist';
import { useCurrentBucketId } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';

import { TabContext } from '../Watchlist/WatchlistTabWrapper';
import { WATCHLIST_TAB_GROUP } from '../WatchlistTabItems';
import style from './style.module.less';

const FixedIncomeMenu = () => {
  const { setActiveTab, activeTabMenuItem, setActiveTabMenuItem } =
    useContext(TabContext);
  const currentBucketId = useCurrentBucketId();
  const { data: tabsToShow } = useTabsToShowOnWatchlist(currentBucketId);

  const formatLabel = (value?: string) => {
    switch (value) {
      case WATCHLIST_TAB_KEY.STYLE_FI:
        return 'FI: Style';
      case WATCHLIST_TAB_KEY.SECTORS_FI:
        return 'FI: Sectors';
      case WATCHLIST_TAB_KEY.GEOGRAPHY_FI:
        return 'FI: Geog';
      case WATCHLIST_TAB_KEY.MATURITY:
        return 'FI: Maturity';
      case WATCHLIST_TAB_KEY.CREDIT_QUALITY:
        return 'FI: Credit Quality';
      default:
        return 'Fixed Income';
    }
  };

  const menuOptions = [
    {
      value: WATCHLIST_TAB_KEY.STYLE_FI,
      label: 'Style',
    },
    {
      value: WATCHLIST_TAB_KEY.SECTORS_FI,
      label: 'Sectors',
    },
    {
      value: WATCHLIST_TAB_KEY.GEOGRAPHY_FI,
      label: 'Geog',
    },
    {
      value: WATCHLIST_TAB_KEY.MATURITY,
      label: 'Maturity',
    },
    {
      value: WATCHLIST_TAB_KEY.CREDIT_QUALITY,
      label: 'Credit Quality',
    },
  ].filter(option => (tabsToShow ? tabsToShow.includes(option.value) : true));

  if (!menuOptions.length) {
    return null;
  }

  return (
    <div className="watchlist-dropdown">
      <Dropdown
        trigger={['hover', 'click']}
        placement="bottomLeft"
        overlay={
          <Menu
            className="min-w-38 p-0"
            items={menuOptions.map(option => {
              const isActive = option.value === activeTabMenuItem;
              return {
                key: option.value,
                label: (
                  <Option
                    isActive={isActive}
                    dataTestId={`tabGroupFixedIncome-${option.value}`}
                    label={option.label}
                  />
                ),
                onClick: () => {
                  setActiveTabMenuItem(option.value);
                  setActiveTab(WATCHLIST_TAB_GROUP.FIXED_INCOME);
                },
              };
            })}
          />
        }
      >
        <div className="flex items-center justify-start gap-x-3">
          {formatLabel(activeTabMenuItem)}{' '}
          <NextArrow
            className={cx('icon', style['button-search-dropdown-caret'])}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default FixedIncomeMenu;
