import {
  BenchmarkOption,
  BenchmarksApi,
  BenchmarkSource,
  CompositeBenchmarkBody,
  CompositeBenchmarksResponse,
} from '@aminsights/contract';
import { useMutation, useQuery } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { BENCHMARK_OPTIONS, BENCHMARKS } from './query-keys';

const benchmarksApi = new BenchmarksApi(openApiConfig());

type UseBenchmarksError = unknown;

const invalidateCompositeBenchmarks = () => {
  queryClient.invalidateQueries({ queryKey: [BENCHMARKS] });
  queryClient.invalidateQueries({ queryKey: [BENCHMARK_OPTIONS] });
};

export const useCompositeBenchmarks = () => {
  return useQuery<CompositeBenchmarksResponse, UseBenchmarksError>(
    [BENCHMARKS],
    async () => {
      const result = await benchmarksApi.getCompositeBenchmarks();

      return result.data;
    },
  );
};

export const useCreateCompositeBenchmark = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { body: CompositeBenchmarkBody }) =>
      benchmarksApi.createCompositeBenchmark(input.body),
    {
      onSettled: () => invalidateCompositeBenchmarks(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully added a benchmark' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error adding benchmark',
        });
      },
    },
  );
  return mutation;
};

export const useUpdateCompositeBenchmark = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { id: string; body: CompositeBenchmarkBody }) =>
      benchmarksApi.updateCompositeBenchmark(input.id, input.body),
    {
      onSettled: () => invalidateCompositeBenchmarks(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully applied changes' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error benchmark link',
        });
      },
    },
  );
  return mutation;
};

export const useDeleteCompositeBenchmark = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { id: string }) => benchmarksApi.deleteCompositeBenchmark(input.id),
    {
      onSettled: () => invalidateCompositeBenchmarks(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully deleted a benchmark' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error deleting benchmark',
        });
      },
    },
  );
  return mutation;
};

export const useBenchmarkOptions = (
  sources: BenchmarkSource[] = [
    BenchmarkSource.Morningstar,
    BenchmarkSource.Msci,
  ],
) => {
  const { state: featureSwitchState } = useFeatureSwitchContext();
  const isBloombergBenchmarksEnabled =
    featureSwitchState.isBloombergBenchmarksEnabled;
  return useQuery<BenchmarkOption[], UseBenchmarksError>(
    [BENCHMARK_OPTIONS, sources],
    async () => {
      const response = await benchmarksApi.getBenchmarkOptions();
      if (isBloombergBenchmarksEnabled) {
        sources.push(BenchmarkSource.Bloomberg);
      }
      const result = response.data.benchmarkOptions;

      if (sources) {
        return result.filter(item => sources.includes(item.source));
      }
      return result;
    },
  );
};
