import './style.less';

import {
  PageQueryParametersSortDirectionEnum,
  SignificantChangesItem,
} from '@aminsights/contract';
import { LoadingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { DataTable } from '@/components/Table';
import useGetSignificantChange from '@/hooks/query-hooks/significant-changes-hooks/useGetSignificantChange';
import useGetSignificantChangesThreshold from '@/hooks/query-hooks/significant-changes-hooks/useGetSignificantChangesThreshold';
import useSignificantChangesInfiniteQuery from '@/hooks/query-hooks/significant-changes-hooks/useSignificantChangesInfiniteQuery';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import useProvideSignificantChanges from '@/pages/app/SignificantChanges/useProvideSignificantChanges';
import useSignificantChangesColumns from '@/partials/SignificantChanges/columns';

import SignificantChangesContentModal from '../SignificantChangesContentModal';
import SignificantChangesEmptyState from '../SignificantChangesEmptyState';

type SignificantChangesTableProps = {
  isins: string[];
  emptyMessage?: string;
};

const UNIQUE_KEY = '_id';

const SignificantChangesTable: React.FCWithChild<
  SignificantChangesTableProps
> = ({ emptyMessage, isins }) => {
  const { state, setSignificantChangeParameters } =
    useProvideSignificantChanges();
  const currentWatchlist = useCurrentWatchlist();
  const significantChangesResponse = useSignificantChangesInfiniteQuery(isins, {
    enabled: !currentWatchlist.isLoading,
  });
  const significantChangesColumns = useSignificantChangesColumns();

  const [significantChangesModalData, setSignificantChangesModalData] =
    useState<SignificantChangesItem>();

  const [
    isSignificantChangesModalVisible,
    setIsSignificantChangesModalVisible,
  ] = useState<boolean>(false);

  const totalNumberOfItems =
    significantChangesResponse?.data?.pages?.[0]?.total ?? 0;
  const { isLoading, isFetching } = significantChangesResponse ?? {};

  const flattenedData: SignificantChangesItem[] =
    significantChangesResponse?.data?.pages.map(p => p.data).flat() ?? [];

  const { data: significantChangesThreshold } =
    useGetSignificantChangesThreshold();

  const significantChanges = useMemo(() => {
    const composedSignificantChangesData = flattenedData.map(
      significantChange => ({
        ...significantChange,
      }),
    );

    return composedSignificantChangesData;
  }, [JSON.stringify(flattenedData), isins]);

  useEffect(() => {
    setPage(1);
  }, [
    isins,
    state.significantChangesParameters.sortKey,
    state.significantChangesParameters.sortDirection,
  ]);

  useEffect(() => {
    if (state.significantChangesParameters.page === 0) {
      setPage(1);
    }
  }, [state.significantChangesParameters]);

  const onSortHandler = (
    sortKey: keyof SignificantChangesItem,
    sortDirection: PageQueryParametersSortDirectionEnum,
  ) => {
    setPage(1);
    setSignificantChangeParameters({ sortKey, sortDirection, page: 0 });
  };

  const handlePagination = async (params: { page: number }) => {
    if (params.page > 1) {
      significantChangesResponse?.fetchNextPage({ pageParam: params.page });
    }
  };

  const { setPage, lastElementRef } = useInfiniteScroll(
    totalNumberOfItems,
    flattenedData,
    handlePagination,
    [isins, state.significantChangesParameters.page],
  );

  const handleOpenModal = (data: SignificantChangesItem) => {
    setSignificantChangesModalData(data);
    setIsSignificantChangesModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsSignificantChangesModalVisible(false);
  };

  const defaultLocation = {
    pathname: '/significant-changes/bucket/all',
  };
  const clearUrlFromQueryParameters = () => {
    history.replace(defaultLocation);
  };

  const history = useHistory();
  const { search: locationSearch } = useLocation();
  const query = new URLSearchParams(locationSearch);

  const [idForModal, setIdForModal] = useState('');
  const id = query.get('id');

  useEffect(() => {
    if (id) {
      setIdForModal(id);
      clearUrlFromQueryParameters();
    }
  }, [id]);

  const dataForModal = useGetSignificantChange(idForModal);

  useEffect(() => {
    if (dataForModal && dataForModal.data) {
      handleOpenModal(dataForModal.data);
      setIdForModal('');
    }
  }, [dataForModal]);

  const handleOnRow = (id: string) => {
    if (id && significantChanges.length) {
      const data = significantChanges.find(
        sc =>
          // eslint-disable-next-line
          sc._id === id,
      );
      if (data) {
        handleOpenModal(data);
      }
    } else {
      throw Error('Selected fund has no id!');
    }
  };

  return (
    <>
      <Modal
        title="Significant Changes"
        className="max-sm:full-page-modal information-modal [&_.ant-modal-body]:pb-10 [&_.ant-modal-header]:shadow-sm"
        open={isSignificantChangesModalVisible}
        onCancel={handleCloseModal}
        onClose={handleCloseModal}
        footer={null}
      >
        {significantChangesModalData && (
          <SignificantChangesContentModal
            data={significantChangesModalData}
            toggleModal={() => setIsSignificantChangesModalVisible(false)}
          />
        )}
      </Modal>

      {!totalNumberOfItems && !isLoading ? (
        <SignificantChangesEmptyState
          customMessage={emptyMessage}
          noThresholds={!significantChangesThreshold}
        />
      ) : (
        <DataTable
          uniqueKey={UNIQUE_KEY}
          loading={isLoading}
          onSort={(
            key: string,
            direction: PageQueryParametersSortDirectionEnum,
          ) => onSortHandler(key as keyof SignificantChangesItem, direction)}
          columns={significantChangesColumns}
          data={significantChanges}
          onRow={handleOnRow}
          lastElementRef={lastElementRef}
        />
      )}

      {isFetching && (
        <LoadingOutlined
          className="py-2"
          style={{ fontSize: 60, color: '#f0f2f5', width: '98%' }}
          spin
        />
      )}
    </>
  );
};

export default SignificantChangesTable;
