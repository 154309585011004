import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import classNames from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useMemo, useState } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import Button from '@/components/Button';
import SelectedDropdownFilter from '@/components/SearchSelectedFilterIndicator';
import useGetCurrencyRates from '@/hooks/query-hooks/currency-hooks/useGetCurrencyRates';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';

import { generateFundSizeMarks } from '../../../hooks/fundSizeSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';
import { getChangedMarkValues } from '../../../utils/getChangedMarkValues';
import FundSizeSlider from './FundSizeSlider';
import style from './style.module.less';

const RangeSliderDropdown = () => {
  const {
    tentativeFilters,
    filters,
    getMutableFilters,
    updateTentativeFilters,
    syncFilterByPath,
  } = useExploreFilters();
  const [isVisible, setIsVisible] = useState(false);
  const { data: currencyData } = useGetCurrencyRates();
  const fundSizeMarks = useMemo(() => {
    const symbol = currencyData?.currencyCode
      ? getSymbolFromCurrency(currencyData.currencyCode)
      : '£';
    return generateFundSizeMarks(symbol);
  }, [currencyData]);

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    syncFilterByPath('fundSizeFilter');
    setIsVisible(false);
  };

  const [clearDisabled, setClearDisabled] = useState(true);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    setApplyDisabled(
      JSON.stringify(tentativeFilters.fundSizeFilter?.fundSizeRange) ===
        JSON.stringify(filters.fundSizeFilter?.fundSizeRange),
    );
  }, [tentativeFilters, filters]);

  useEffect(() => {
    setClearDisabled(applyDisabled && !filters.fundSizeFilter?.fundSizeRange);
  });

  const { currentWidth } = useScreenWidth();
  const shouldUseMobileFilter = currentWidth < screenBreakpoints.lg;

  useEffect(() => {
    if (shouldUseMobileFilter) {
      setIsVisible(false);
    }
  }, [shouldUseMobileFilter]);

  const menu = (
    <form className={style['button-search-dropdown-form']}>
      <Menu className={style['button-search-dropdown-menu']}>
        <div className={style['button-search-dropdown-menu-items']}>
          <div>
            <div className="flex flex-row justify-between ">
              <div className="flex-column">
                <p>Fund Size</p>
              </div>
              <div className="flex-column">
                <p className="font-semibold">
                  {getChangedMarkValues(
                    tentativeFilters.fundSizeFilter?.fundSizeRange,
                    fundSizeMarks,
                  )}
                </p>
              </div>
            </div>

            <div
              data-test-id={`FundSize`}
              className={classNames(
                style['fund-size-range-slider'],
                style['range-slider'],
              )}
            >
              <FundSizeSlider />
            </div>
          </div>
        </div>
      </Menu>
      <div className={style['button-search-dropdown-form-footer']}>
        <Button
          data-test-id={`clearFundSize`}
          size="large"
          type="link"
          htmlType="button"
          className="font-semibold"
          onClick={() => {
            const f = getMutableFilters();
            f.fundSizeFilter = undefined;
            updateTentativeFilters(f);
          }}
          disabled={clearDisabled}
        >
          Clear
        </Button>
        <Button
          data-test-id={`applyFundSize`}
          size="large"
          type="primary"
          htmlType="submit"
          className="font-semibold"
          onClick={onSubmit}
          disabled={applyDisabled}
        >
          Apply
        </Button>
      </div>
    </form>
  );
  return (
    <>
      <div className={style['button-search-dropdown']}>
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement={'bottomLeft' || 'bottomRight'}
          open={isVisible}
        >
          <Button
            className={cx(
              style['button-search-dropdown-wrapper'],
              'outline outline-1 outline-light',
              !!filters.fundSizeFilter ? 'text-darkest' : 'text-neutral',
              'hover:text-primary hover:fill-primary hover:outline-primary ',
              'focus:text-primary focus:outline-primary',
              'group',
            )}
            onClick={() => setIsVisible(prev => !prev)}
            data-test-id={'fundSize'}
          >
            <SelectedDropdownFilter
              placeholder={'Fund Size'}
              noOfSelectedFilter={filters.fundSizeFilter ? 1 : 0}
              className={cx(!!filters.fundSizeFilter && 'font-semibold')}
            />
            <span className={style['icon-arrow-wrapper']}>
              <NextArrow
                className={cx('icon', style['button-search-dropdown-caret'])}
              />
            </span>
          </Button>
        </Dropdown>
      </div>
      {isVisible && (
        <div
          className={style['button-search-dropdown-overlay']}
          onClick={() => setIsVisible(false)}
          onKeyUp={() => setIsVisible(false)}
          role="button"
          tabIndex={0}
        >
          {' '}
        </div>
      )}
    </>
  );
};

export default RangeSliderDropdown;
