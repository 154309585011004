import { ChartDataTag } from '@aminsights/contract';
import { AreaConfig } from '@ant-design/charts';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import useGetAbsoluteChartData from '@/hooks/query-hooks/chart-hooks/useGetAbsoluteChartData';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { parseRangeValues, useDatePickerContext } from '@/partials/DatePicker';
import ChartsLegendCard from '@/partials/LegendCards/ChartsLegendCard';
import {
  getLegendCardClasses,
  getLegendIdToRemove,
  getLegendSuperTag,
  getLegendTargetLink,
} from '@/utils';

import ChartEmptyState from '../ChartEmptyState';
import BaseChart, { BaseChartProps } from '../components/BaseChart';
import { useDropDownContext } from '../Context';
import { PerformanceCardDetails } from '../utils/chart-data';
import { calculateStrokeColor } from '../utils/colors';
import { getIdOrderByLegendData } from '../utils/get-order';
import useChartColors from '../utils/useChartColors';

interface AbsoluteChartProps extends BaseChartProps {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  scrollable?: boolean;
}

const AbsoluteChart: React.FCWithChild<AbsoluteChartProps> = ({
  showFundLinks = true,
  onRemoveFund,
  isins,
  benchmarkId,
  sectorId,
  featuredIsins,
  scrollable,
  chartColors: chartColorsProps,
}) => {
  const { value: investmentTrustFilter } = useDropDownContext();
  const { value: datePickerValue } = useDatePickerContext();
  const [legendData, setLegendData] = useState<PerformanceCardDetails[]>([]);

  // Contemplating whether to move this out or not
  // eslint-disable-next-line
  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];

  const { data, isLoading, isFetching } = useGetAbsoluteChartData(
    {
      benchmarkId,
      sectorId,
      isins,
      period,
      investmentTrustReturn: investmentTrustFilter.absolute,
      featuredIsins,
    },
    {
      enabled: !!isins.length || !!benchmarkId,
    },
  );
  const absoluteData = data?.data || [];

  const chartColorsHook = useChartColors({
    isins,
    legend: data?.legendData || [],
  });
  const chartColors = chartColorsProps || chartColorsHook;
  const isChartLoaded = useMemo(() => {
    if (!isLoading && !!data) {
      if (chartColors) {
        return !!Object.keys(chartColors).length;
      }
      return true;
    }
    return false;
  }, [data, isLoading, chartColors]);

  useEffect(() => {
    if (data?.legendData?.length && chartColors) {
      setLegendData(
        data.legendData.map(f => ({
          ...f,
          tooltip: f.tooltip || '',
          value: f.value || '',
          id: f.id || '',
          label: f.label || '',
          dataTag: f.dataTag,
          color: chartColors[f.id || ''] || '',
          date: f.date || '',
        })),
      );
    }
  }, [data, chartColors]);

  const tooltipOrder = useMemo(
    () => getIdOrderByLegendData(data?.legendData),
    [data?.legendData],
  );

  const config: AreaConfig = useMemo(
    () => ({
      className: 'custom-antd-chart',
      data: absoluteData,
      color: ({ id }) => {
        if (!id) {
          return 'FFF';
        }
        return chartColors[id] || '';
      },
    }),
    [absoluteData, chartColors],
  );
  // if isins are not defined - show loader no matter what
  // this will mean that we are waiting for isins to be defined to be provided from the parent control

  if (!absoluteData.length && !isLoading && !isFetching) {
    return (
      <ChartEmptyState
        title={'No data available for this fund'}
        isDashboardPage={true}
      />
    );
  }

  return (
    <div>
      {isChartLoaded && (
        <BaseChart
          config={config}
          strokeColor={calculateStrokeColor(datePickerValue.mode)}
          enableHoverEffects={
            !!featuredIsins?.length &&
            legendData.some(d => d.dataTag === ChartDataTag.Featured)
          }
          dataTestId="absoluteChart"
          onUpdateLegend={setLegendData}
          scrollable={scrollable}
          tooltipOrder={tooltipOrder}
        />
      )}
      {(isLoading || !isChartLoaded) && (
        <img className="w-full mt-5 mb-5" src={loaderImage} />
      )}
      <div
        data-test-id="absoluteChartLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            // Sort based on toolTipOrder - matched by id
            .sort(
              (a, b) => tooltipOrder.indexOf(a.id) - tooltipOrder.indexOf(b.id),
            )
            .map(legendItem => (
              <ChartsLegendCard
                key={legendItem.id}
                label={legendItem.label}
                value={legendItem.value}
                tooltip={legendItem.tooltip || legendItem.label}
                superTag={getLegendSuperTag(legendItem.dataTag)}
                id={getLegendIdToRemove(legendItem)}
                date={legendItem.date}
                targetLink={
                  showFundLinks ? getLegendTargetLink(legendItem) : undefined
                }
                color={legendItem.color}
                dataTag={legendItem.dataTag}
                cardClasses={getLegendCardClasses(
                  legendItem.dataTag,
                  legendItem.isActive,
                )}
                onRemove={onRemoveFund}
              />
            ))}
        />
      </div>
    </div>
  );
};

export default AbsoluteChart;
