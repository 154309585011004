import { Button } from 'antd';
import { FC } from 'react';

import { ReactComponent as GraphicCommentary } from '@/assets/svg/graphic-commentary.svg';

interface EmptyStateProps {
  onClick: () => void;
  title: string;
  subTitle?: string;
  buttonLabel: string;
  icon?: React.ReactNode;
}

const EmptyState: FC<EmptyStateProps> = ({
  onClick,
  title,
  subTitle,
  buttonLabel,
  icon = <GraphicCommentary />,
}) => (
  <div className="flex flex-col items-center justify-center w-full text-center">
    {icon}
    <div className="flex flex-col gap-y-2 mb-4">
      <h3 className="text-sm not-italic font-semibold mt-2 text-darkest">
        {title}
      </h3>
      {subTitle && <p className="leading-5 text-neutral-200">{subTitle}</p>}
    </div>
    <Button
      onClick={onClick}
      className="px-4 py-2.5 text-sm not-italic font-medium rounded"
      type="primary"
    >
      {buttonLabel}
    </Button>
  </div>
);

export default EmptyState;
