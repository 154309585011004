import cx from 'classnames';
import React, { ReactNode } from 'react';

type SelectedDropdownFilterProps = {
  placeholder?: string;
  noOfSelectedFilter: number;
  className?: string;
};

const selectedDropdownFilter = (selectedFilter: number): ReactNode => {
  return (
    <>
      <div className={`mx-2`}>&bull;</div> {selectedFilter}
    </>
  );
};

const SelectedDropdownFilter: React.FCWithChild<
  SelectedDropdownFilterProps
> = ({ placeholder, noOfSelectedFilter, className }) => {
  return (
    <div className={cx(`flex items-center`, className)}>
      {placeholder}{' '}
      {noOfSelectedFilter > 0 && selectedDropdownFilter(noOfSelectedFilter)}
    </div>
  );
};

export default SelectedDropdownFilter;
