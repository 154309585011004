import { ReactComponent as WarningIcon } from '@/assets/svg/icons/icon-error-v3.svg';

const NoResultWarning = () => {
  return (
    <div className="flex gap-3 p-4 mt-1 bg-destructive-50 rounded">
      <div>
        <WarningIcon className="w-5 h-5" />
      </div>
      <div className="flex-1 flex flex-col gap-1">
        <p className="text-sm text-neutral-200 font-bold">
          This request wasn’t recognised
        </p>
        <p className="text-neutral-700 text-sm leading-5">
          Update your request to use common terms such as small cap or value
        </p>
      </div>
    </div>
  );
};

export default NoResultWarning;
