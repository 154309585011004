import { Material } from '@aminsights/contract';
import { USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Skeleton } from 'antd';
import { FC, useContext, useMemo, useState } from 'react';

import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useDeleteMaterial } from '@/hooks/query-hooks/internal-hooks/useManageMaterials';
import { validatePermissions } from '@/utils/auth';

import MaterialFormModal from '../MaterialFormModal';
import MaterialListItem from '../MaterialListItem';

interface MaterialsProps {
  isLoading?: boolean;
  materials?: Material[];
  fundId?: string;
}

const Materials: FC<MaterialsProps> = ({ materials, fundId, isLoading }) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState<
    Material | undefined
  >();
  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;

  const deleteMaterial = useDeleteMaterial();

  const handleToggleFormModal = () => {
    setIsFormModalOpen(prev => !prev);
  };

  const handleEditMaterial = (material: Material) => {
    setSelectedMaterial(material);
    handleToggleFormModal();
  };

  const handleCloseModal = () => {
    setSelectedMaterial(undefined);
    handleToggleFormModal();
  };

  const handleDeleteMaterial = async (material: Material) => {
    if (!fundId) return;
    await deleteMaterial.mutateAsync({ fundId, materialId: material._id });
  };

  const hasWritePermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.materials.write],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const areMaterialsAvailable = materials?.length;

  return (
    <div className="bg-white p-4 lg:rounded-lg">
      <p className="text-base font-semibold">Materials</p>
      {isLoading ? (
        <Skeleton
          title={false}
          paragraph={{ rows: 1, width: 150 }}
          style={{ marginTop: 10 }}
          active
        />
      ) : (
        <div>
          {materials?.map(material => (
            <MaterialListItem
              key={material._id}
              material={material}
              onEditMaterial={handleEditMaterial}
              onDeleteMaterial={handleDeleteMaterial}
            />
          ))}
          {!areMaterialsAvailable && !hasWritePermissions && (
            <p className="pt-1">No link available</p>
          )}
          {!areMaterialsAvailable && hasWritePermissions && (
            <Button
              onClick={handleToggleFormModal}
              type="link"
              className="p-0 text-link font-medium border-none shadow-none"
            >
              + Add link
            </Button>
          )}
        </div>
      )}
      <MaterialFormModal
        isVisible={isFormModalOpen}
        initialValue={selectedMaterial}
        onCloseModal={handleCloseModal}
        fundId={fundId}
      />
    </div>
  );
};

export default Materials;
