import { ChartsApi, UnboxedChartDataResponse } from '@aminsights/contract';
import {
  RipsReturnType,
  unboxChartDataItemsToChartDataSeries,
} from '@aminsights/shared';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import {
  CHARTS,
  CHARTS_SHARE_PRICE_VS_NAV,
} from '../watchlist-hooks/query-keys';

export interface ChartParams {
  period: string[];
  isins: string[];
  benchmarkId?: string;
  ripsReturnType?: number;
  investmentTrustReturn?: RipsReturnType[];
  featuredIsins?: string[];
  retainSortOrder?: boolean;
}

const useGetSharePriceVsNavChartData = (
  params: ChartParams,
  options?: Omit<
    UseQueryOptions<
      UnboxedChartDataResponse | null,
      unknown,
      UnboxedChartDataResponse
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const {
    period,
    isins,
    benchmarkId,
    investmentTrustReturn,
    featuredIsins,
    retainSortOrder,
  } = params;
  const chartApi = new ChartsApi(openApiConfig());

  const enabled = isins.length !== 0 && period.length === 2;

  return useQuery<
    UnboxedChartDataResponse | null,
    unknown,
    UnboxedChartDataResponse
  >(
    [CHARTS, CHARTS_SHARE_PRICE_VS_NAV, params],
    async () => {
      if (!enabled) return null;

      const response = await chartApi.getSharePriceVsNavChartData(
        benchmarkId,
        isins,
        period,
        featuredIsins,
        retainSortOrder,
        investmentTrustReturn,
      );
      const unboxedData = unboxChartDataItemsToChartDataSeries(
        response?.data?.data,
      );
      return {
        ...response?.data,
        data: unboxedData,
      };
    },
    {
      ...options,
    },
  );
};

export default useGetSharePriceVsNavChartData;
