import { ChartDataTag } from '@aminsights/contract';

import { PerformanceCardDetails } from '@/partials/Charts/utils/chart-data';
import { ScatterPlotCardDetails } from '@/partials/ScatterPlots/utils/scatter-plot-data';

export const getLegendSuperTag = (dataTag?: ChartDataTag) => {
  switch (dataTag) {
    case ChartDataTag.Benchmark:
      return 'Index';
    case ChartDataTag.Featured:
      return 'Focus Fund';
    case ChartDataTag.Portfolio:
      return 'Portfolio';
    case ChartDataTag.Sector:
      return 'Sector';
    default:
      return '';
  }
};

export const getLegendBackgroundClass = (dataTag?: ChartDataTag) => {
  switch (dataTag) {
    case ChartDataTag.Benchmark:
      return 'bg-grey-light-50';
    case ChartDataTag.Portfolio:
      return 'bg-[#fcebf9]';
    case ChartDataTag.Sector:
      return 'bg-[#EBF7E1]';
    case ChartDataTag.Featured:
      return 'bg-[#E6F9F6]';
    default:
      return '';
  }
};

export const getLegendCardClasses = (
  dataTag?: ChartDataTag,
  isActive?: boolean,
) => {
  return {
    backgound: getLegendBackgroundClass(dataTag),
    weight:
      [ChartDataTag.Featured, ChartDataTag.Benchmark].includes(
        dataTag as ChartDataTag,
      ) || isActive
        ? 'font-bold'
        : '',
  };
};

export const getLegendTargetLink = (
  data: PerformanceCardDetails | ScatterPlotCardDetails,
) => {
  if (data.dataTag === ChartDataTag.Portfolio)
    return `/portfolios/${data.id}/holdings`;
  else
    return data.isin
      ? `/fund/${data.isin.toUpperCase()}/details/summary`
      : undefined;
};

export const getLegendIdToRemove = (
  data: PerformanceCardDetails | ScatterPlotCardDetails,
) => {
  if (ChartDataTag.Benchmark === data.dataTag) {
    return data.id;
  } else if (ChartDataTag.Portfolio === data.dataTag) {
    return data.id?.split('_')?.[0];
  } else if (
    [ChartDataTag.Default, ChartDataTag.Featured].includes(
      data.dataTag as ChartDataTag,
    ) &&
    data.isin
  ) {
    return data.isin;
  }

  return undefined;
};
