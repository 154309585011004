import { NoteBody, NoteCategory, NoteStatus } from '@aminsights/contract';
import { Modal } from 'antd';
import { Input } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';

import Button from '@/components/Button';

type ModalProps = {
  modalInfo: {
    title?: string;
    primaryActionDisabled?: boolean;
    succesMessage?: string;
    errorMessage?: string;
    contentType: NoteCategory;
  };
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  loading?: boolean;
  children?: React.ReactNode;
  width?: number;
  onSubmit: (note: any) => void;
};
const { TextArea } = Input;
const NoteModal: React.FCWithChild<ModalProps> = ({
  modalInfo,
  isVisible,
  className,
  loading,
  toggleModal,
  width = 400,
  onSubmit,
}) => {
  const [textAreaValue, setTextAreaValue] = useState('');
  const [isLimitReached, setIsLimitReached] = useState(false);
  const maxCharacters = 2000;

  const handleTextChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (value.length <= maxCharacters) {
      setTextAreaValue(value);
      setIsLimitReached(false);
    } else {
      setIsLimitReached(true);
    }
  };
  const onClick = () => {
    const note: NoteBody = {
      fundId: '',
      description: textAreaValue,
      category: modalInfo.contentType,
      status: NoteStatus.Draft,
    };
    onSubmit(note);
    setTextAreaValue('');
  };

  return (
    <Modal
      centered={true}
      width={width}
      open={isVisible}
      onCancel={() => {
        toggleModal();
        setTextAreaValue('');
      }}
      className={cx(
        'action-modal',
        'action-modal-confirmation',
        'md:min-w-[600px] lg:min-w-[800px]',
        '[&_.ant-modal-body]:py-6 [&_.ant-modal-header]:pt-10 [&_.ant-modal-header]:pb-0 [&_.ant-modal-close]:mr-4 [&_.ant-modal-footer]:pt-0',
        className,
      )}
      title={
        <div className="flex gap-x-2">
          <div className="text-xl font-bold text-darkest">
            {modalInfo.title}
          </div>
        </div>
      }
      footer={[
        <Button
          size="large"
          type="link"
          key="secondary-save"
          onClick={toggleModal}
          data-test-id="modalNoteContentCancelButton"
        >
          Cancel
        </Button>,
        <Button
          size="large"
          onClick={onClick}
          key="primary-confirm"
          loading={loading}
          disabled={!textAreaValue}
          data-test-id="modalNoteContentAddButton"
          type="primary"
        >
          Add
        </Button>,
      ]}
    >
      <div className="text-base font-normal leading-6">
        <span className="text-xs not-italic font-medium leading-4 text-darkest">
          {modalInfo.contentType}
        </span>
        <div className="text-area-wrapper">
          <TextArea
            className="!placeholder:text-sm !placeholder:text-neutral-100 !placeholder:font-normal !rounded border !border-[#BAC0D0] !border-solid h-[280px] !pb-0 text-sm !text-neutral-700 px-4 py-2 resize-none hover:!border-solid focus:!border-[#007FFF] focus:!border-2"
            id=""
            name=""
            placeholder="Write something..."
            value={textAreaValue}
            onChange={handleTextChange}
          />
          {isLimitReached && (
            <p className="mt-1 text-red-500 text-xs">
              You have hit the 2,000 character limit. Reduce the characters and
              also let us know if this is a regular problem for you.
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NoteModal;
