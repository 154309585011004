import dayjs, { Dayjs } from 'dayjs';

import { WEEKDAYS, weekdayToNumber } from '../constants';

/**
 * Retrieves all dates between a start and end date that fall on a specified day of the week.
 *
 * @param startDate The starting date of the range, as a Dayjs object.
 * @param endDate The ending date of the range, as a Dayjs object.
 * @param weekDay The desired day of the week, as a string from the WEEKDAYS enum.
 * @returns An array of date strings in the format 'YYYY-MM-DD' that fall on the specified day of the week within the given range.
 *
 * @description This function iterates over each date between the startDate and endDate (inclusive) and checks if the
 *              date falls on the specified weekDay. If a date matches the desired day of the week, its string representation
 *              in the format 'YYYY-MM-DD' is added to the dates array. The function continues iterating until the startDate
 *              is equal to or greater than the endDate. Finally, it returns the array of matching date strings.
 *
 * @note The function assumes that the WEEKDAYS enum contains string values representing the days of the week in uppercase.
 *       It uses the Dayjs library for date manipulation and comparison.
 */
export const getAllDatesByDayOfTheWeek = (
  startDate: string,
  endDate: string,
  dayOfTheWeek: WEEKDAYS,
): string[] => {
  const formatDate = (date: Date) => {
    const year = date.getFullYear(); // Get the full year (4 digits)
    const month = date.getMonth() + 1; // Get month (0-11), so add 1 to make it 1-12
    const day = date.getDate(); // Get day of the month (1-31)

    // Pad the month and day with leading zeros if necessary
    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    const formattedDay = day < 10 ? `0${day}` : day.toString();

    // Combine year, month, and day with dashes
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const from = new Date(startDate);
  from.setHours(0, 0, 0, 0);

  const to = new Date(endDate);
  to.setHours(0, 0, 0, 0);

  const result: Date[] = [];
  const current = new Date(from.getTime()); // Clone the from date
  current.setHours(0, 0, 0, 0); // Normalize time part

  const dayOfWeekFrom = current.getDay();
  let daysToAdd = (weekdayToNumber[dayOfTheWeek] - dayOfWeekFrom + 7) % 7;

  if (daysToAdd === 0 && current <= to) {
    result.push(new Date(current));
    daysToAdd = 7; // Set to one week for subsequent adds
  } else {
    current.setDate(current.getDate() + daysToAdd); // Jump to the first matching day
  }

  // Continue weekly jumps
  while (current <= to) {
    result.push(new Date(current));
    current.setDate(current.getDate() + 7);
  }

  return result.map(formatDate);
};

/**
 * Retrieves all dates between a start and end date that are the last day of each month.
 *
 * @param startDate The starting date of the range, as a string in 'YYYY-MM-DD' format.
 * @param endDate The ending date of the range, as a string in 'YYYY-MM-DD' format.
 * @returns An array of date strings in the format 'YYYY-MM-DD' that are the last day of each month within the given range.
 */
export const getAllEndOfMonthDates = (
  startDate: string,
  endDate: string,
): string[] => {
  const from = new Date(startDate);
  from.setHours(0, 0, 0, 0);

  const to = new Date(endDate);
  to.setHours(0, 0, 0, 0);

  const start = dayjs(from).endOf('month');

  const result: string[] = [];
  let current = start;

  while (current.isBefore(to) || current.isSame(to, 'day')) {
    result.push(current.format('YYYY-MM-DD'));
    current = current.add(1, 'month').endOf('month');
  }

  return result;
};

/**
 * Adjusts a given date to the previous Friday if it falls on a weekend.
 *
 * @param date The input date as a Dayjs object.
 * @returns The adjusted date as a Dayjs object.
 *
 * @description This function takes a date and checks if it falls on a weekend (Saturday or Sunday).
 *              If the date is a Saturday (day of week 6), it subtracts 1 day to roll back to Friday.
 *              If the date is a Sunday (day of week 0), it subtracts 2 days to roll back to Friday.
 *              If the input date is a weekday (Monday to Friday), the function returns the date unchanged.
 *
 * @note The function assumes that the input date is a valid Dayjs object.
 *       It uses the Dayjs library for date manipulation and comparison.
 *       The returned date is a new Dayjs object, leaving the original input date unmodified.
 */
export const rollbackDateIfWeekend = (date: dayjs.Dayjs): dayjs.Dayjs => {
  const dayOfWeek = date.day();

  if (dayOfWeek === 6) {
    return date.subtract(1, 'days');
  }

  if (dayOfWeek === 0) {
    return date.subtract(2, 'days');
  }

  return date;
};

/**
 * Finds the closest past date in an array of objects with date property.
 *
 * @param data An array of objects, each containing a 'date' property as a string.
 * @param targetDate The target date to find the closest past date to, as a string.
 * @returns The object from the input array with the closest past date to the target date,
 *          or undefined if the input array is empty.
 *
 * @description This function uses a binary search algorithm to efficiently find the closest
 *              past date in the input array. It assumes that the input array is sorted in
 *              ascending order by date. If an exact match for the target date is found, the
 *              function returns the corresponding object. If no exact match is found, the
 *              function compares the dates on either side of the target date and returns the
 *              object with the closest past date. If the target date is before all dates in
 *              the array, the function returns the object with the earliest date. If the
 *              target date is after all dates in the array, the function returns the object
 *              with the latest date.
 */
export const findClosestPastDate = <T extends { date: string }>(
  data: T[],
  targetDate: string,
): T | undefined => {
  const date = dayjs(targetDate);
  let left = 0;
  let right = data.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midDate = dayjs(data[mid].date);

    if (midDate.isSame(date, 'day')) {
      return data[mid];
    } else if (midDate.isBefore(date)) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  // Check the dates on either side of the target date
  const leftDate = data[left] ? dayjs(data[left].date) : null;
  const rightDate = data[right] ? dayjs(data[right].date) : null;

  if (!leftDate) return data[right];
  if (!rightDate) return data[left];

  return leftDate.diff(date) <= rightDate.diff(date) ? data[left] : data[right];
};

export const closestPastFriday = (date: Dayjs) => {
  const dayOfWeek = date.day();
  let daysSinceFriday: number;

  if (dayOfWeek === 5) {
    return date.subtract(7, 'day'); // If it's Friday, go back a week
  } else if (dayOfWeek > 5) {
    daysSinceFriday = dayOfWeek - 5;
  } else {
    daysSinceFriday = 5 - dayOfWeek;
  }

  return date.subtract(daysSinceFriday, 'day');
};

// Function to get the closest last date of the last month unless it is the same day
export const closestLastDateOfMonth = (date: Dayjs): Dayjs => {
  const today = date.date();
  const lastDayOfCurrentMonth = date.endOf('month').date();

  if (today === lastDayOfCurrentMonth) {
    return date;
  }

  const lastDateOfLastMonth = date.subtract(1, 'month').endOf('month');
  return lastDateOfLastMonth;
};

export const getLastWeekday = (includeToday = false) => {
  let lastWeekday = dayjs();

  if (!includeToday) {
    lastWeekday = lastWeekday.subtract(1, 'day');
  }

  // If today is Saturday or Sunday, subtract days to get the last Friday
  while (
    lastWeekday.day() === weekdayToNumber[WEEKDAYS.SUNDAY] ||
    lastWeekday.day() === weekdayToNumber[WEEKDAYS.SATURDAY]
  ) {
    lastWeekday = lastWeekday.subtract(1, 'day');
  }

  return lastWeekday;
};
