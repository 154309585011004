import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
  CHARTS,
  CHARTS_PORTFOLIO_ABSOLUTE,
  CHARTS_PORTFOLIO_RELATIVE,
} from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import PortfolioAbsoluteChart from '@/partials/Charts/absolute/PortfolioAbsoluteChart';
import PortfolioRelativeChart from '@/partials/Charts/relative/PortfolioRelativeChart';
import PortfolioRiskReturnScatterPlot from '@/partials/ScatterPlots/risk-return-scatter-plot/PortfolioRiskReturnScatterPlot';

import { useProvidePortfolio } from '../../../useProvidePortfolio';
import EmptyPortfolioTable from '../../EmptyPortfolioTable';
import style from '../PortfolioHoldingsTab/style.module.less';

const PortfolioChartsTab: React.FCWithChild = () => {
  const { state, addFundSearchBoxToCurrentPortfolio } = useProvidePortfolio();
  const queryClient = useQueryClient();

  const currentPortfolio = state.portfolios.find(
    p => p._id === state.currentPortfolioId,
  );
  const portfolioFundIds = currentPortfolio
    ? currentPortfolio.funds?.map(f => f.id)
    : [];
  const portfolioBenchmarkIds = currentPortfolio
    ? currentPortfolio.benchmarks?.map(b => b.secId)
    : [];
  const dataIds = [...portfolioFundIds, ...portfolioBenchmarkIds].filter(
    Boolean,
  ) as string[];

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [CHARTS, CHARTS_PORTFOLIO_ABSOLUTE],
    });
    queryClient.invalidateQueries({
      queryKey: [CHARTS, CHARTS_PORTFOLIO_RELATIVE],
    });
  }, [JSON.stringify(dataIds)]);

  const isEmpty = currentPortfolio?.funds.length === 0;

  return (
    <div className={style.chartsTabContainer}>
      {!isEmpty ? (
        <div className="py-6 grid gap-4">
          <PortfolioAbsoluteChart portfolioId={state.currentPortfolioId} />
          <PortfolioRelativeChart portfolioId={state.currentPortfolioId} />
          <PortfolioRiskReturnScatterPlot
            portfolioId={state.currentPortfolioId}
          />
        </div>
      ) : (
        <EmptyPortfolioTable
          currentPortfolioId={state.currentPortfolioId}
          onAddFundClick={addFundSearchBoxToCurrentPortfolio}
        />
      )}
    </div>
  );
};

export default PortfolioChartsTab;
