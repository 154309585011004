import { isInvestmentTrust } from '@aminsights/shared';

import { useMultipleFundsByIsins } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import ChartEmptyState from '@/partials/Charts/ChartEmptyState';
import PremiumDiscountChart from '@/partials/Charts/premium-discount/PremiumDiscountChart';

import useChartTrustFundsFromSearch from '../utils/useChartFundsFromSearch';
import { useChartingContext } from './ChartingContext';

const PremiumDiscountChartingToolPage = () => {
  const { isins, setIsins } = useChartTrustFundsFromSearch();
  const { data, isLoading } = useMultipleFundsByIsins(isins);
  const investmentTrustIsins = data
    ?.filter(f => isInvestmentTrust(f?.legalStructure || ''))
    .map(f => f.shareClassDetails.isin);

  const chartingCtx = useChartingContext();
  const legendData = chartingCtx.chartLegend;
  const chartColors = chartingCtx.chartColors;

  if (!isins.length) {
    return (
      <ChartEmptyState
        title="No added funds/trusts to chart"
        subtitle="Search funds to chart"
        isDashboardPage={true}
      />
    );
  }
  if (!investmentTrustIsins?.length && !isLoading) {
    return (
      <ChartEmptyState
        title="Premium/discount not available"
        subtitle="Select an investment trust"
        isDashboardPage={true}
      />
    );
  }
  return (
    <PremiumDiscountChart
      isins={investmentTrustIsins || []}
      allIsins={isins}
      onRemoveFund={isin => {
        setIsins([isin], true);
        const targetId = legendData.find(ld => ld.isin === isin)?.id || '';
        const targetColor = chartColors[targetId];
        targetColor && chartingCtx.setColorsRemoved(targetColor);
      }}
      showFundLinks={true}
      chartColors={chartColors}
    />
  );
};

export default PremiumDiscountChartingToolPage;
