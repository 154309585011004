import {
  ChartsApi,
  RiskDetailsTypeEnum,
  ScatterPlotResponseWithPortfolio,
} from '@aminsights/contract';
import { RipsReturnType } from '@aminsights/shared';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import {
  CHARTS,
  CHARTS_RISK_RETURN_WITH_PORTFOLIO,
} from '../watchlist-hooks/query-keys';

export interface ChartParams {
  period: string;
  isins: string[];
  benchmarkId?: string;
  riskDetailsType?: RiskDetailsTypeEnum;
  investmentTrustReturn?: RipsReturnType;
  featuredIsins?: string[];
  retainSortOrder?: boolean;
  portfolios?: string[];
}

const useGetRiskReturnDataWithPortfolio = (
  params: ChartParams,
  options?: Omit<
    UseQueryOptions<
      ScatterPlotResponseWithPortfolio | null,
      unknown,
      ScatterPlotResponseWithPortfolio
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const {
    period,
    isins,
    benchmarkId,
    riskDetailsType,
    featuredIsins,
    retainSortOrder,
    portfolios,
  } = params;
  const chartApi = new ChartsApi(openApiConfig());

  const enabled = (isins.length !== 0 || portfolios?.length !== 0) && period;

  return useQuery<
    ScatterPlotResponseWithPortfolio | null,
    unknown,
    ScatterPlotResponseWithPortfolio
  >(
    [CHARTS, CHARTS_RISK_RETURN_WITH_PORTFOLIO, params],
    async () => {
      if (!enabled) return null;

      const response = await chartApi.getRiskReturnPlotDataWithPortfolio(
        benchmarkId,
        isins,
        riskDetailsType,
        featuredIsins,
        retainSortOrder,
        portfolios,
        period,
      );

      return response?.data;
    },
    { ...options },
  );
};

export default useGetRiskReturnDataWithPortfolio;
