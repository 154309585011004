import { ChartDataTag } from '@aminsights/contract';

import AbsoluteChartTitle from '@/partials/Charts/absolute/AbsoluteChartTitle';
import AbsoluteChartWithPortfolio from '@/partials/Charts/absolute/AbsoluteChartWithPortfolio';
import ChartEmptyState from '@/partials/Charts/ChartEmptyState';

import useChartTrustFundsFromSearch from '../utils/useChartFundsFromSearch';
import { useChartingContext } from './ChartingContext';

const AbsoluteChartingToolPage = () => {
  const {
    isins,
    benchmark,
    setIsins,
    setBenchmark,
    hasInvestmentTrust,
    portfolios,
    setPortfolios,
  } = useChartTrustFundsFromSearch();

  const chartingCtx = useChartingContext();
  const legendData = chartingCtx.chartLegend;
  const chartColors = chartingCtx.chartColors;

  if (!(isins.length || portfolios.length)) {
    return (
      <ChartEmptyState
        title="No added funds/trusts/portfolios to chart"
        subtitle="Search funds/portfolios to chart"
        isDashboardPage={true}
      />
    );
  }
  return (
    <>
      <AbsoluteChartTitle
        hasInvestmentTrust={hasInvestmentTrust}
        showDropdown={true}
      />
      <AbsoluteChartWithPortfolio
        onRemoveFund={(id, dataTag) => {
          if (dataTag === ChartDataTag.Benchmark) {
            setBenchmark('');
            return;
          }
          if (dataTag === ChartDataTag.Portfolio) {
            setPortfolios([id], true);
            const targetId =
              legendData.find(ld => ld.id?.includes(id))?.id || '';
            const targetColor = chartColors[targetId];
            targetColor && chartingCtx.setColorsRemoved(targetColor);
            return;
          }
          const targetId = legendData.find(ld => ld.isin === id)?.id || '';
          const targetColor = chartColors[targetId];
          targetColor && chartingCtx.setColorsRemoved(targetColor);
          setIsins([id], true);
        }}
        showDropdown={true}
        isins={isins}
        benchmarkId={benchmark}
        hasInvestmentTrust={hasInvestmentTrust}
        resetInvReturnFilterOnChange={false}
        portfolios={portfolios}
        chartColors={chartColors}
      />
    </>
  );
};

export default AbsoluteChartingToolPage;
