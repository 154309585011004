import {
  SignificantChangesApi,
  SignificantChangesList,
} from '@aminsights/contract';
import { ChangeTypeEnum } from '@aminsights/shared';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import { SIZE_PER_PAGE as size } from '@/constants';
import { useSignificantChangesContext } from '@/pages/app/SignificantChanges/Context';
import { openApiConfig } from '@/utils';

import calculateNextPageForInfiniteQuery from '../util';
import { SIGNIFICANT_CHANGES } from '../watchlist-hooks/query-keys';

const useSignificantChangesInfiniteQuery = (
  isins: string[],
  //to be removed when feature switch is removed
  options?: Omit<
    UseInfiniteQueryOptions<SignificantChangesList, unknown>,
    'queryKey' | 'queryFn'
  >,
) => {
  const { state } = useSignificantChangesContext();
  const queryParams = {
    ...state.significantChangesParameters,
  };

  return useInfiniteQuery<SignificantChangesList, unknown>(
    [SIGNIFICANT_CHANGES, isins, queryParams],
    async ({ pageParam = 1 }) => {
      const { term, sortKey, sortDirection, changeTypes, period } = queryParams;
      //to be removed when feature switch is removed
      let tempChangeTypes = changeTypes;
      if (changeTypes?.length === 0) {
        tempChangeTypes = Object.values(ChangeTypeEnum);
      }
      const significantChangesApi = new SignificantChangesApi(openApiConfig());
      const response = await significantChangesApi.getSignificantChanges(
        pageParam,
        size,
        term,
        sortKey,
        sortDirection,
        isins,
        //to be returned to changeType when feature switch is removed
        tempChangeTypes,
        period,
      );
      return response.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return calculateNextPageForInfiniteQuery(lastPage.total, pages);
      },
      enabled: isins.length > 0,
      ...options,
    },
  );
};

export default useSignificantChangesInfiniteQuery;
