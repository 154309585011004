import { ResourceUserRoleEnum } from '@aminsights/contract';
import { Select } from 'antd';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as ArrowDownStroke } from '@/assets/svg/icons/icon-arrow-down-stroke.svg';

import styles from './style.module.less';

interface IOption {
  label: string;
  value: ResourceUserRoleEnum;
}

export const roleOptions: IOption[] = [
  {
    label: 'Viewer',
    value: ResourceUserRoleEnum.Viewer,
  },
  {
    label: 'Editor',
    value: ResourceUserRoleEnum.Editor,
  },
];

interface Props<T = unknown> {
  value: ResourceUserRoleEnum;
  onChange: (value: T | ResourceUserRoleEnum) => void;
  options?: IOption[];
  customOptions?: boolean;
  className?: string;
  popupClassName?: string;
  bordered?: boolean;
  children?: React.ReactNode;
}

const RoleSelect = <T,>({
  onChange,
  value,
  options,
  children,
  customOptions,
  className,
  popupClassName,
  bordered,
}: Props<T>) => {
  return (
    <Select
      data-test-id="sharePortfolioRoleDropdown"
      className={cx(
        styles['role-select'],
        'group min-w-[90px] w-full min-h-10',
        '[&>.ant-select-selector]:rounded [&>.ant-select-selector]:border-neutral-50',
        '[&>.ant-select-selector>.ant-select-selection-item:hover]:!text-[#007FFF]',
        '[&>.ant-select-selector>.ant-select-selection-item]:h-[inherit] flex justify-center items-center [&>.ant-select-selector>.ant-select-selection-item]:flex [&>.ant-select-selector>.ant-select-selection-item]:justify-center [&>.ant-select-selector>.ant-select-selection-item]:items-center',
        '[&.ant-select-open>.ant-select-selector>.ant-select-selection-item]:!text-[#007FFF]',
        '[&.ant-select-open>.ant-select-arrow>svg]:fill-[#007FFF]',

        className,
      )}
      popupMatchSelectWidth={false}
      options={customOptions ? undefined : options || roleOptions}
      value={value}
      onChange={onChange}
      popupClassName={cx([styles['role-select-options'], popupClassName])}
      bordered={bordered}
      suffixIcon={
        <ArrowDownStroke
          height={8}
          width={10}
          className="fill-neutral-700 group-hover:fill-[#007FFF] !transition-colors !duration-200"
        />
      }
    >
      {children}
    </Select>
  );
};

export default RoleSelect;
