import { NoteCategory, NoteStatus } from '@aminsights/contract';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';

type CardNotesProps = {
  noteCategory: NoteCategory;
  note: string;
  status: NoteStatus;
  date: string;
  author: string;
};

const CardNotes: React.FCWithChild<CardNotesProps> = ({
  noteCategory,
  note,
  status,
  date,
  author,
}) => {
  return (
    <div className="flex flex-col self-stretch rounded border border-[color:var(--Neutral-300,#BAC0D0)] p-4 border-solid">
      <div className="flex justify-between items-center w-full mb-2.5 py-0.5">
        <span className="text-sm font-bold">{noteCategory}</span>
        <div className="flex items-center gap-2">
          <p className="text-neutral-500 text-right text-ellipsis text-[10px] leading-4">
            Last Updated:{' '}
            <span className="font-semibold text-neutral">{author}</span> {date}
          </p>
          <span
            className={cx(
              'text-xs text-neutral-400 px-1.5 py-0.5 rounded',
              status === 'Draft'
                ? 'bg-[#FCF4C7] !text-[#B87E14]'
                : 'bg-[#F3FAED] !text-[#007D00]',
            )}
          >
            {status}
          </span>
          <div className="hover:bg-grey-light-50 cursor-pointer py-1.5 rounded">
            <MeatballMenu
              width={16}
              height={16}
              className="text-neutral-700 mx-2"
            />
          </div>
        </div>
      </div>
      <div>
        <p>{note}</p>
      </div>
    </div>
  );
};

export default CardNotes;
