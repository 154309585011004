import { Fund } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  getYtdMonthCode,
  isFund,
  MonthCode,
} from '@aminsights/shared';
import { TotalReturnPeriod } from '@aminsights/shared';
import { calculatePerformanceTableBackgroundColor } from '@aminsights/shared';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { TableCheckboxHeader } from '@/pages/app/Explore/components/TableCheckbox';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { RenderAnnualisedReturns } from '../RenderFundValues/RenderAnnualisedReturns';
import FundNameCheckboxWrapper, {
  CheckboxParams,
} from './FundNameCheckboxWrapper';

const AnnualisedPerformanceColumns = (
  checkboxParams: CheckboxParams,
  dataTableRenderedAt: DataTableRenderedAt,
  showPrimaryShareClassIndicator?: boolean,
): IDataTableColumns[] => {
  const screenWidthMode = getScreenWidthMode();

  const { state } = useFeatureSwitchContext();
  const { isComputedRiskDetailsTreatmentEnabled } = state;

  const monthCodeOfYtd = getYtdMonthCode(dayjs.tz(undefined, 'UTC'));

  return [
    {
      headerCheckbox: (): React.ReactNode =>
        dataTableRenderedAt !== DataTableRenderedAt.Watchlist && (
          <div className="">
            <TableCheckboxHeader
              onClick={checkboxParams.onCheckedRow}
              topISINs={checkboxParams.topISINs}
              checked={checkboxParams.isAllCheckboxChecked}
            />
          </div>
        ),
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      sortKey: 'shareClassDetails.code',
      render: (item: Fund): React.ReactNode => {
        return (
          <FundNameCheckboxWrapper
            item={item}
            checkboxParams={checkboxParams}
            dataTableRenderedAt={dataTableRenderedAt}
            showPrimaryShareClassIndicator={
              showPrimaryShareClassIndicator ?? false
            }
          />
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'YTD',
      idSubText: 'ytd',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: isComputedRiskDetailsTreatmentEnabled
            ? `computedNavAnnualisedReturns.percentage.${monthCodeOfYtd}`
            : 'totalReturnYtdPct',
          render: (item: Fund) => {
            return <RenderAnnualisedReturns fund={item} showYtd />;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturnYtdMorningstarCategoryPcl',
          render: (item: Fund): string => {
            const value =
              isFund(item) && item?.totalReturnYtdMorningstarCategoryPcl;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.YTD,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '3 mos',
      idSubText: '3mos',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: isComputedRiskDetailsTreatmentEnabled
            ? `computedNavAnnualisedReturns.percentage.${MonthCode.M3}`
            : 'totalReturn3MonthPct',
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M3} />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn3MonthMorningstarCategoryPcl',
          render: (item: Fund): string => {
            const value =
              isFund(item) && item?.totalReturn3MonthMorningstarCategoryPcl;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.THREE_MONTHS,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '6 mos',
      idSubText: '6mos',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: isComputedRiskDetailsTreatmentEnabled
            ? `computedNavAnnualisedReturns.percentage.${MonthCode.M6}`
            : 'totalReturn6MonthPct',
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M6} />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn6MonthMorningstarCategoryPcl',
          render: (item: Fund): string => {
            const value =
              isFund(item) && item?.totalReturn6MonthMorningstarCategoryPcl;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.SIX_MONTHS,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '1 yr',
      idSubText: '1yr',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: isComputedRiskDetailsTreatmentEnabled
            ? `computedNavAnnualisedReturns.percentage.${MonthCode.M12}`
            : 'totalReturn1YearPct',
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M12} />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn1YearMorningstarCategoryPcl',
          render: (item: Fund): string => {
            const value =
              isFund(item) && item?.totalReturn1YearMorningstarCategoryPcl;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.ONE_YEAR,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '3 yrs',
      idSubText: '3yrs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: isComputedRiskDetailsTreatmentEnabled
            ? `computedNavAnnualisedReturns.percentage.${MonthCode.M36}`
            : 'totalReturn3YearPct',
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M36} />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn3YearMorningstarCategoryPcl',
          render: (item: Fund): string => {
            const value =
              isFund(item) && item?.totalReturn3YearMorningstarCategoryPcl;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.THREE_YEARS,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '5 yrs',
      idSubText: '5yrs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: isComputedRiskDetailsTreatmentEnabled
            ? `computedNavAnnualisedReturns.percentage.${MonthCode.M60}`
            : 'totalReturn5YearPct',
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M60} />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn5YearMorningstarCategoryPcl',
          render: (item: Fund): string => {
            const value =
              isFund(item) && item?.totalReturn5YearMorningstarCategoryPcl;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.FIVE_YEARS,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '',
      render: () => '',
      renderType: 'text',
    },
  ];
};

export default AnnualisedPerformanceColumns;
