export const openJiraForm = () => {
  const iframe = document.querySelector('#jsd-widget') as HTMLIFrameElement;
  iframe?.style.setProperty('display', 'block');
  const iframeContent = iframe?.contentDocument as Document;
  const button = iframeContent.querySelector('#help-button') as HTMLElement;
  button?.click();
  // Wait for the modal to open and add a listener to close it
  setTimeout(addJiraModalCloseListener, 1000);
};

const addJiraModalCloseListener = () => {
  const iframe = document.querySelector('#jsd-widget') as HTMLIFrameElement;
  const iframeContent = iframe?.contentDocument as Document;
  const closeButton = iframeContent.querySelector(
    'button[aria-label="Dismiss"]',
  ) as HTMLElement;
  closeButton?.addEventListener('click', () => {
    iframe?.style.setProperty('display', 'none');
  });
};
